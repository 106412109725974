import React, { useState, useRef } from 'react'
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Loading from './Loading';
import { toast } from 'react-toastify';




export default function Login() {

  let navigate = useNavigate(); // For Navigation Reference
  const inputRefMobile = useRef(); //For Mobile Focus Reference
  const inputRefPassword = useRef();//For Password Focus Reference

  //State Management
  const [MobileNo, setMobileNo] = useState('');
  const [Password, setPassword] = useState('');

  //Redirection Method
  const routeChange = (path) => {
    navigate('../' + path, { replace: true });
  };

  //Handling Input Change
  const handleChange = (event) => {
    if (event.target.name == "txtMobileNo") {
      setMobileNo(event.target.value);
    } else {
      setPassword(event.target.value);
    }
  }
  //Handling Loading 
  const [isLoading, setIsLoading] = useState(false);

  //Ajax Calling For Login
  const doLogin = () => {
    if (MobileNo == "") {
      toast.error('Enter Mobile No.')
      inputRefMobile.current.focus();
      return;
    }
    if (MobileNo.toString().length < 10 || MobileNo.toString().length > 15) {
      toast.error('Enter Valid Mobile No.');
      inputRefMobile.current.focus();
      return;
    }
    if (Password == "") {
      toast.error('Enter Password.');
      inputRefPassword.current.focus();
      return;
    }
    setIsLoading(true);
    var obj = {};
    obj = new Object();
    obj.UserId = MobileNo;
    obj.Password = Password;
    var param = new Object();
    param.procName = "SolidityLogin"
    param.para = JSON.stringify(obj);
    axios({
      method: 'POST',
      url: process.env.REACT_APP_WEBAPI_URL,
      data: param
    }).then(function (res) {
      setIsLoading(false);
      res = res.data;
      if (res[0].StatusCode == "1") {
        toast.success('Logged-In Successfully...');
        var UserDetails = JSON.stringify(res[0]);
        UserDetails = UserDetails.hexEncode();
        localStorage.setItem("UserDetails", UserDetails);
        setTimeout(function () {
          routeChange('home');
        }, 1000)

      } else {
        toast.error(res[0].msg);
      }
    }).catch(function (error) {
      setIsLoading(false);
      console.log(error);
    });
  }
  const [passwordType, setPasswordType] = useState("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  }
  //Component Part
  return (
    <>
      {isLoading ? <Loading /> : ""}

      <div className='signinBg'>
        <div className="signin-wrap wrap" >

          

          <img onClick={() => window.location.href = 'https://t.me/cryptoyardadm'} src="https://cdn-icons-png.flaticon.com/512/4298/4298326.png" style={{ height: 35, marginTop: '-158px', float: 'right' }}></img>
          <div className="logo mylogo">
            <img src="img/logo.png" />
          </div>
          <div>

          </div>
          <ul id="app" style={{ padding: 0, marginBottom: 0, marginTop: 29 }}>
            <li className="item" style={{ lineHeight: '0.88rem', border: '1px solid #ffc107' }}>
              <input id="txtMobileNo" ref={inputRefMobile} onChange={handleChange} type="text" name="txtMobileNo" placeholder="Enter Mobile No." />
            </li>
            <li className="item" style={{ lineHeight: '0.88rem', border: '1px solid #ffc107' }}>
              <input id="txtPassword" ref={inputRefPassword} onChange={handleChange} type={passwordType} name="txtPassword" placeholder="Enter Password" />
              {passwordType === "password" ? <i className="myeye fa fa-eye-slash" onClick={togglePassword}></i> : <i className="myeye fa fa-eye" onClick={togglePassword}></i>}
            </li>

            {/* <label className="switch">
              <input type="checkbox" defaultChecked />

              <span className="slider round"></span>
            </label>
            <span style={{ fontSize: '13px', position: 'absolute', marginLeft: '6px', color: '#fff' }}>Rememeber the password</span> */}

          </ul>
          <button className="btn btn-info" style={{ marginTop: '0%',width:'89%',marginLeft:'5%', color: '#fff', border: 'none', textTransform: 'uppercase' }} onClick={doLogin}>SIGN IN</button>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '90%', marginLeft: '26%', marginTop: '0%' }}>
            <Link to="/createaccount" style={{ fontSize: 14, textDecoration: 'none', color: '#000', marginLeft: '24px', margin: 5, padding: 0 }}>No Account? Register Now
            </Link>

            {/* <Link to='/recoverpassword' style={{ fontSize: 14, textDecoration: 'none', color: '#000', margin: 0, padding: 0 }}>Recover
              Password</Link> */}
          </div>
          {/* <a href='TronDicev1.0.0.apk' style={{ marginLeft: '0%', marginTop: '-184px', float: 'left' }} download><img src='img/appdownload.png' style={{ height: 35 }}></img><span style={{ fontSize: '12px', color: '#000' }}> Downlaod App</span></a> */}
          <div style={{ color: '#000', padding: 5, fontSize: 13, marginLeft: '0%', textAlign: 'center', marginTop: 50, }}>
            Copyright 2020-2023 Cryptoyard - All rights reserved</div>

        </div>
        
      </div>
      <style
        dangerouslySetInnerHTML={{
          __html: `
          // body {
          //   background:black url(http://fc01.deviantart.net/fs71/f/2012/160/b/9/stars_by_paulinemoss-d52un4e.jpg);
          //   animation: stars 250s linear alternate;
          // }
          // @keyframes stars {
          //   0% { background-position:0 0 }
          //   100% { background-position:0 100% }
          // }
          ::placeholder {
            color: #000 !important;
            font-family: Microsoft YaHei !important;
            
        }
        .mylogo{
          width:'82%!important';
        }
        .signin-wrap {
          /* width: auto; */
          border-radius: 50px 0px;
          /* overflow: hidden; */
          padding: 10px 20px;
          margin: 10;
          height: 100%;
          bottom: -30%;
          position: absolute;
          width: 100%;
      }
      .btn-info.focus, .btn-info:focus{
        box-shadow: none !important;
    }
    .myeye{
      margin-left: 86%!important;
    font-size: 15px!important;
    background-color: #000;
    color:#fff;
    padding: 5px;
    border-radius: 5px;
    /* margin-bottom: -29px; */
    margin-top: -2px;
    line-height: 1;
    }
        i {
            font-size: 18px !important;
            color: #fff !important;
            position: absolute !important;
            top: 12px !important;
            left: 10px !important;
            z-index: 99 !important;
        }

        .navbar {
            max-width: 750px;
            position: fixed;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            height: .8rem;
            background: #337bd3 !important;
            width: 100%;
            left: 50%;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
            top: 0;
            z-index: 30;
        }

        .navbar span {
            text-align: center;
            font-size: .36rem;
            font-weight: 400;
            color: #ffffff !important;
        }

        input:focus {
            outline: none !important;
            border-color: #719ECE;
        }

        .navbar {
            justify-content: flex-start;
        }

        .btn.register {
            background: #FFFFFF;
            color: #337bd3;
            border: 1px solid #0058AB;
            margin: 0 auto;
        }

        .item {
            width: 5.98rem;
            height: 0.88rem;
            background: #fff;
            border-radius: 0.14rem;
            margin: 0 auto;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            margin-bottom: 0.3rem;
            position: relative;
            left: 0px;
        }

        .logo {
            width: 279px;
            height: auto;
            margin: auto;
        }
       

        a,
        p {
            font-family: Microsoft YaHei !important;
        }

        .item:nth-child(1) input {
            padding-left: 17px;
        }

        .item:nth-child(2) input {
            padding-left: 17px;
        }   
        .switch {
          position: relative;
          display: inline-block;
          width: 50px;
          height: 23px;
          margin-left: 6%;
        }
        
        .switch input { 
          opacity: 0;
          width: 0;
          height: 0;
        }
        
        .slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #ccc;
          -webkit-transition: .4s;
          transition: .4s;
        }
        
        .slider:before {
          position: absolute;
          content: "";
          height: 18px;
          width: 18px;
          left: 4px;
          bottom: 3px;
          background-color: white;
          -webkit-transition: .4s;
          transition: .4s;
        }
        
        input:checked + .slider {
          background-color: #FBBD18;
        }
        
        input:focus + .slider {
          box-shadow: 0 0 1px #FBBD18;
        }
        
        input:checked + .slider:before {
          -webkit-transform: translateX(26px);
          -ms-transform: translateX(26px);
          transform: translateX(26px);
        }
        
        /* Rounded sliders */
        .slider.round {
          border-radius: 34px;
        }
        
        .slider.round:before {
          border-radius: 50%;
        }
        `
        }}
      />
    </>
  )
}
