import React, { useState, useEffect, useRef } from 'react'
import axios from "axios";
import Loading from './Loading';
import { toast } from 'react-toastify';
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import BackButton from './BackButton';
export default function Withdraw() {


    let navigate = useNavigate();
    const routeChange = (path) => {
        navigate('../' + path, { replace: true });
    };
    const inputEls = useRef([]);
    const [isLoading, setIsLoading] = useState(false);
    const [bankdetail, setBankDetail] = useState({
        mobileNo: "",
        accountNo: "",
        fullName: "",
        ifsc: ""
    })
    const [disabled, setDisabled] = useState(false);
    const [walletAmount, setwalletAmount] = useState(0);
    const [token, setToken] = useState("");
    const [allValues, setAllValues] = useState({
        WithdrawAmount: '',
        Password: '',
        WalletAddress: ''
    });
    const changeHandler = e => {
        setAllValues({ ...allValues, [e.target.name]: e.target.value })
    }
    useEffect(() => {
        GetWalletAmount();
    }, []);
    const GetWalletAmount = () => {
        setIsLoading(true);
        var param = new Object();
        param.MemberId = JSON.parse(localStorage.getItem("UserDetails").hexDecode()).ClientId;
        param.ActionMode = "GetWalletAmount";
        var obj = new Object();
        obj.procName = 'SenderRegistration';
        obj.Para = JSON.stringify(param);
        axios({
            method: 'POST',
            url: process.env.REACT_APP_WEBAPI_URL,
            data: obj
        }).then(function (res) {
            setIsLoading(false);
            res = res.data;
            setwalletAmount(res[0].WalletAmount);
            setToken(res[0].Token);
            //Setting Bank Details
            setBankDetail({ mobileNo: res[0].MobileNo, accountNo: res[0].AccountNo, fullName: res[0].AccountHolderName, ifsc: res[0].IFSC });
            setAllValues({ WalletAddress: res[0].WalletAddress, WithdrawAmount: '', Password: '' });
            if (res[0].WalletAddress != "") {
                setDisabled(true);
            }
        }).catch(function (error) {
            setIsLoading(false);
            console.log(error);
        });
    }
    const ProceedToWithdraw = () => {
        if ((parseFloat(allValues.WithdrawAmount) || 0) == 0) {
            toast.error('Enter Withdraw Amount.');
            inputEls.current[1].focus();
            return;
        }
        if (allValues.WalletAddress == "") {
            toast.error('Enter Wallet Address.');
            inputEls.current[3].focus();
            return;
        }
        if (allValues.Password == "") {
            toast.error('Enter Transaction Password.');
            inputEls.current[2].focus();
            return;
        }
        setIsLoading(true);
        var Obj = new Object();
        Obj.ClientId = JSON.parse(localStorage.getItem("UserDetails").hexDecode()).ClientId;
        Obj.WalletType = "CommissionWallet";
        Obj.WithdrawAmount = allValues.WithdrawAmount;
        Obj.LoginPassword = allValues.Password;
        Obj.WalletAddress = allValues.WalletAddress;
        Obj.Token = token;
        var objWithdraw = new Object();
        objWithdraw.procName = 'GetOnlineWIthdrawAmountByWalletType';
        objWithdraw.Para = JSON.stringify(Obj);
        axios({
            method: 'POST',
            url: process.env.REACT_APP_WEBAPI_URL,
            data: objWithdraw
        }).then(function (res) {
            setIsLoading(false);
            res = res.data;
            if (res[0].StatusCode == "1") {
                GetWalletAmount();
                toast.success(res[0].Msg);
                inputEls.current[1].value = "";
                inputEls.current[3].value = "";
            }
            else {
                if (res[0].StatusCode == "0") {
                    toast.error(res[0].Msg);
                } else {
                    toast.error('Something went wrong, plz try again later');
                }
            }
        }).catch(function (error) {
            setIsLoading(false);
        });
    }
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Withdraw TRX</title>
                <link rel="stylesheet" href="css/Recharge.css" />
                <link rel="stylesheet" href="css/font-awesome.min.css" />
            </Helmet>
            {isLoading ? <Loading /> : ""}
            <div class="recharge-wrap" style={{ backgroundColor: 'none' }}>
                <div className="navbar">
                    <BackButton path='withdraw' />
                    <span style={{ marginRight: '25%', marginTop: '-15px' }}>Withdraw Earnings</span>

                </div>
                <div className='row' style={{ margin: 0 }}>
                    <div className='col-12'>
                        <div className='grayblkmy'>
                            <div style={{ height: '25px', }}><span className='normaltxt' style={{ color: '#000' }}>Withdrawable Balance</span></div>
                            <div className='textFigure'><span style={{ color: '#000' }}>{walletAmount} TRX</span></div>
                        </div>
                        <img className='myicon' src='img/tronlogo.png' style={{ height: '50px' }}></img>
                    </div>
                </div>
                {/* <div className='row wallet'>
                    <div className='col-12'>
                        <div className='graybg'>
                        <div style={{ height: '25px', }}><span className='normaltxt' style={{ color: '#fff' }}>Withdrawable Balance</span></div>
                            <div className='textFigure'><span style={{ color: '#fff' }}>{walletAmount} TRX</span></div>
                        </div>
                        <img className='myicon' src='img/tronlogo.png' style={{ height: '106px' }}></img>
                    </div>
                </div> */}
                <div className="list-wrap list-wrap2">

                    <div className="rechargedetail" style={{ marginTop: 5, background: 'none', padding: 0, borderRadius: 8 }}>
                        <div style={{ display: 'block !important' }}>
                            {/* <div style={{ display: 'block !important' }}><div style={{ fontSize: '18px', color: '#fff' }}>Withdraw Amount</div></div> */}
                            <div style={{ display: 'block !important' }}>
                                <input type="number" ref={(el) => (inputEls.current[1] = el)} onChange={changeHandler} className="number_only withInput" name='WithdrawAmount' id="WithdrawAmount" placeholder="Enter  Amount" style={{ width: '100%', height: '40px', fontSize: '18px'}} />
                            </div>
                        </div>
                        <div style={{ display: 'block !important' }}>
                            {/* <div style={{ display: 'block !important' }}><div style={{ fontSize: '18px', color: '#fff' }}>Wallet Address(TRON)</div></div> */}
                            <div style={{ display: 'block !important' }}>
                                <input type="text" value={allValues.WalletAddress} ref={(el) => (inputEls.current[3] = el)} onChange={changeHandler} className="withInput" name='WalletAddress' id="WalletAddress" placeholder="Enter TRC20 Wallet Address" style={{ width: '100%', height: '40px', fontSize: '18px' }} />
                            </div>
                        </div>
                        <div style={{ display: 'block' }}>
                            {/* <div>
                                <div style={{ margin: 0, fontSize: 18, fontWeight: 'normal', color: '#000' }}>Login Password:</div>
                            </div> */}
                            <div style={{ marginLeft: 10 }}>
                                <p style={{ margin: 0, fontSize: 14, fontWeight: 'normal', color: '#5a5252' }} id="wAccountNo"></p>
                            </div>
                        </div>


                        <div>
                            <input style={{ width: '100%', fontSize: 15, paddingLeft: 4, border: '1px solid #eee !important', borderRadius: '8px !important', height: 40}} type="password" ref={(el) => (inputEls.current[2] = el)} onChange={changeHandler} id="Password" name="Password" className="withInput" placeholder="Enter Password" />
                        </div>
                    </div>

                    <div className="rechargedetail" onClick={ProceedToWithdraw} style={{ marginTop: 5, background: 'none', padding: 10, borderRadius: 8 }}>
                        <button type="button" id="btnSubmit" style={{ margin: 0, color: '#fff' }} className="btn btn-dark" >Withdraw</button>
                    </div>
                    <center><img src='https://cdn3d.iconscout.com/3d/premium/thumb/bitcoin-locker-5682785-4748156.png' className='partnerPayment'></img></center>
                    {/* <p style={{ color: '#000', fontSize: 12, fontStyle: 'italic', marginTop: 10,lineHeight:1.2 }}>
                        Please make sure you are entering correct TRX Wallet Address of TRC 20 chain of TRON Blockchain, You can change desired withdrawal wallet address anhytime there is no restriction.
                    </p> */}
                    {/* <center><span className='badge badge-dark' style={{fontSize:15}}>Miminum Withdrawal 1 TRX</span></center> */}
                    {/* <div className="withdrawalInstruction" style={{ background: 'none', padding: 10, borderRadius: 8, marginBottom: 55 }}>
                        <div style={{ paddingBottom: 10 }}>
                            <p className="tips" style={{ fontSize: 14, color: 'gray' }}>
                                <div style={{ marginBottom: '7px' }}>  1. Please provide a valid TRX wallet address.</div>

                                <div style={{ marginBottom: '7px' }}>2. Withdrawal is instant.</div>
                                <div style={{ marginBottom: '7px' }}>2. Withdrawal Fee: 1%.</div>
                                <div style={{ marginBottom: '7px' }}>3. Withdraw Timing: 24x7 No Holiday, No Sunday.</div>
                                <div style={{ marginBottom: '7px' }}>4. Minimum withdrawal amount is 5 TRX.</div>

                            </p>
                        </div>
                    </div> */}
                    <input type="hidden" id="token" />
                </div>

            </div>
            <style
                dangerouslySetInnerHTML={{
                    __html: `
                    .partnerPayment{
                        margin-top: 5px!important;
                        height: 226px;
                        border-radius: 120px;
                    
                    }
                    .wallet{
                        background:aquamarine;
                        background-size: 100% 100%;
                        border-radius: 31px;
                        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
                        margin: 15px;
                      }
                      .myicon{
                        height: 50px;
                        position: absolute;
                        margin-left: 77%;
                        margin-top: -61px;
                      }
                    .recharge-wrap{
                        background:none;
                    }
                    /* Chrome, Safari, Edge, Opera */
                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                    }
                    ::placeholder {
                        color: #000 !important;
                        font-family: Microsoft YaHei !important;
                        font-style:italic;
                        font-size:14px;
                    }
                    .withInput {
                        width: 100%;
                        font-size: 16px !important;
                        padding: -3px !important;
                        /* height: 40px; */
                        /* background-color: transparent !important; */
                        border: 0px;
                        /* border: solid 1px #17a2b8; */
                        /* margin-bottom: 10px; */
                        box-shadow: none;
                        border-radius: 8px;
                        background-color: #38ffd157;
                    }
                  
                    /* Firefox */
                    input[type=number] {
                    -moz-appearance: textfield;
                    }
                    // body {
                    //     background:black url(http://fc01.deviantart.net/fs71/f/2012/160/b/9/stars_by_paulinemoss-d52un4e.jpg);
                    //     animation: stars 250s linear alternate;
                    //   }
                    //   @keyframes stars {
                    //     0% { background-position:0 0 }
                    //     100% { background-position:0 100% }
                    //   }
                    .lock {
                        font-size: 18px !important;
                        color: #000 !important;
                        position: absolute !important;
                        top: 30px !important;
                        left: 12px !important;
                        z-index: 99 !important;
                    }
           
                    .list-wrap.list-wrap2 .price div {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        height: auto;
                        -webkit-box-align: end;
                        -ms-flex-align: end;
                        align-items: flex-end;
                        font-size: .6rem;
                        -webkit-transform: translateY(-0.22rem);
                        transform: translateY(-0.22rem);
                        margin-right: 11px !important;
                    }
            
                    .list-wrap.list-wrap2 .price {
                        background: #fff;
                        height: auto !important;
                        border-radius: 0.2rem;
                        padding-left: 0.2rem;
                        margin-top: 14px;
                    }
            
                        .list-wrap.list-wrap2 .price div input {
                            width: 90%;
                            font-size: 15px;
                            padding-left: 30px;
                            border: 1px solid #eee !important;
                            border-radius: 8px !important;
                            height: 40px;
                        }
            
                    input:focus {
                        outline: none !important;
                        border-color: #719ECE;
                    }
            
                    .list-wrap .btn {
                        line-height: 42px;
                    }
            
                    .topbg {
                        height: 82px !important;
                        overflow: hidden;
                        background: #ffffff;
                        background-size: 100% auto;
                        color: #FFFFFF;
                        padding-left: 0.26rem;
                    }
            
            
            
                    .list-wrap .btn {
                        height: 0.88rem;
                        line-height: 44px;
                        background: #181d1f;
                        border-radius: 4px;
                        text-align: center;
                        color: #fff;
                        font-size: 14px;
                        font-weight: 400;
                        margin: 0.6rem 0 0.7rem 0;
                        cursor: pointer;
                        opacity: 1;
                        pointer-events: all;
                        width: 100%;
                        font-family: Microsoft YaHei !important;
                    }
            
                    .tips {
                        font-size: 10px;
    color: #fff !important;
    font-style: normal;
    font-weight:normal !important;
    margin-bottom:10px;
                    }
                    .btn-info{
                        border:none;
                        border-radius:5px;
                    }
          `
                }}
            />
        </>
    )
}
