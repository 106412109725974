import React from 'react'
import QRCode from 'react-qr-code';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import BackButton from './BackButton';
import { useNavigate, Link } from "react-router-dom";
export default function Invite() {
    let navigate = useNavigate();
    const routeChange = (path) => {
        navigate('../' + path, { replace: true });
    };
    const [value, setValue] = useState("");
    const [userName, setUserName] = useState("");
    const [back, setBack] = useState('#FFFFFF');
    const [fore, setFore] = useState('#000');
    const [size, setSize] = useState(150);
    useEffect(() => {
        let URL = "";
        let uName = "";
        let xCode = JSON.parse(localStorage.getItem("UserDetails").hexDecode());
        uName = xCode.UserName;
        URL = xCode.ReferralURL;
        setValue(URL + uName);
        setUserName(uName);
    }, []);
    const CopyCallBack = (t, r) => {
        if (r == true) {
            toast.success("Invite Code Copied!!");
        }
    }
    return (
        <>
            <div className="recharge-wrap recharge-wrap2">
                <div className="navbar">
                    <BackButton path='home' />
                    <span style={{ marginRight: '25%', marginTop: '-15px' }}>Invite Friends</span>

                </div>
                <div className=" list-wrap3" style={{ margin: 5 }}>
                    <div className="billdeailsnew">
                        <div className="billlist">
                            <div className="billddiscription" style={{ borderRadius: 0, margin: '29px 15px', boxShadow: 'rgba(0,0,0,.2) -1px 1px 12px 0px', background: '#0000' }}>
                                <div className="invoce_header myheader" style={{  color: '#000' }}>
                                    <img className='myimage' src='https://us.123rf.com/450wm/skathi/skathi1906/skathi190600011/124610953-refer-a-friend-marketing-design-with-man-and-woman-silhouette-giving-a-high-five-advertising-concept.jpg?ver=6'></img>
                                    <p style={{ margin: 0, fontSize: 17, color: '#000', padding: '0px 0', textAlign: 'center' }}>
                                        Your Referral Code : <span className='badge badge-success' style={{backgroundColor:'#000'}}>{userName}</span>
                                    </p>

                                </div>
                                <div className style={{ textAlign: 'center', padding: '10px 15px 25px' }}>
                                    {/* <p style={{ margin: 0, fontSize: 17, color: '#000', padding: '15px 0', textAlign: 'center',margintop:'-22px' }}>
                                        Scan QR Code to Share
                                    </p> */}
                                    {/* <p style={{ margin: 0, fontSize: 17, color: '#fff', padding: '0px 0', textAlign: 'center', marginLeft: 16, marginTop: '5px' }}>

                                        <img src='img/lvl.jpg' className='lvl'></img>
                                    </p> */}
                                    {/* <QRCode
                                        title="TronDice"
                                        value={value}
                                        bgColor={back}
                                        fgColor={fore}
                                        size={size === '' ? 0 : size}
                                    /> */}
                                    <input value={value} type="text" style={{ width: '94%', borderRadius: '40px', fontSize: 12, border: 'none', marginTop: 5, marginBottom: 10, marginLeft: 0, height: '0.82rem', background: 'linear-gradient(315deg, #B2EBF2, #B2EBF2)', paddingLeft: '0.4rem', fontWeight: 'normal', color: '#000' }} id="refLink" />
                                    <CopyToClipboard text={value} onCopy={CopyCallBack}>
                                        <center><button className='cryptoButton'>TAP TO COPY</button></center>
                                    </CopyToClipboard>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="usercard" style={{ marginTop: '-20px'}}>
                        <h4 style={{ margin: '6px 8px', fontSize: 15, color: '#000' }}>Our 3 Level Deposit Commission
                            Distribution</h4>
                        <div className="userdetail" style={{ height: 70, background: '#212529', textAlign: 'left', padding: 2, margin: 5, borderRadius: 55, display: 'flex' }}>
                            <div className="userimag" style={{ marginLeft: 98 }}>
                                <img src="https://cdn-icons-png.flaticon.com/512/6557/6557109.png" style={{ marginBottom: 38, background: '#fff', borderRadius: 50, height: 40, width: 40 }} />
                            </div>
                            <div className="username" style={{ marginLeft: 20 }}>
                                <p style={{ margin: 0, fontSize: 12, color: '#fff' }}>LEVEL 1</p>
                                <h5 style={{ margin: 0, color: '#fff', fontSize: 12 }}>
                                    <span style={{ fontSize: 20 }} className='badge badge-warning'>11%</span> </h5>
                            </div>
                        </div>
                        <div className="userdetail" style={{ height: 70, background: '#212529', padding: 2, margin: 5, borderRadius: 55, display: 'flex' }}>
                            <div className="userimag" style={{ marginLeft: 98 }}>
                                <img src="https://cdn-icons-png.flaticon.com/512/6557/6557109.png" style={{ marginBottom: 38, background: '#fff', borderRadius: 50, height: 40, width: 40 }} />
                            </div>
                            <div className="username" style={{ marginLeft: 20 }}>
                                <p style={{ margin: 0, fontSize: 12, color: '#fff' }}>LEVEL 2</p>
                                <h5 style={{ margin: 0, color: '#fff', fontSize: 12 }}><span className='badge badge-warning' style={{ fontSize: 20 }}>3%</span>.
                                </h5>
                            </div>
                        </div>
                        <div className="userdetail" style={{ height: 70, background: '#212529', padding: 2, margin: 5, borderRadius: 55, display: 'flex' }}>
                            <div className="userimag" style={{ marginLeft: 98 }}>
                                <img src="https://cdn-icons-png.flaticon.com/512/6557/6557109.png" style={{ marginBottom: 38, background: '#fff', borderRadius: 50, height: 40, width: 40 }} />
                            </div>
                            <div className="username" style={{ marginLeft: 20 }}>
                                <p style={{ margin: 0, fontSize: 12, color: '#fff' }}>LEVEL 3</p>
                                <h5 style={{ margin: 0, color: '#fff', fontSize: 12 }}><span style={{ fontSize: 20 }} className='badge badge-warning'>2%</span>.
                                </h5>
                            </div>
                        </div>
                        {/* <div className="userdetail" style={{ background: '#5cb85c', padding: 2, margin: 5, borderRadius: 5, display: 'flex' }}>
                            <div className="userimag">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/e/e9/Tether_USDT.png" style={{ background: '#fff', borderRadius: 50, height: 40, width: 40 }} />
                            </div>
                            <div className="username" style={{ marginLeft: 20 }}>
                                <p style={{ margin: 0, fontSize: 12, color: '#fff' }}>LEVEL 4</p>
                                <h5 style={{ margin: 0, color: '#fff', fontSize: 12 }}><span style={{ fontSize: 20 }}>1%</span>.
                                </h5>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            <style
                dangerouslySetInnerHTML={{
                    __html: `
                    .myimage{
                        height: 241px;
                        margin-top: 12px;
                        margin-left: 22%;
                        border-radius: 0px;
                    }
                    .lvl{
                        height: 61px;
                         margin-left: -5%;
                    }
                    .myheader{
                        background: #fff;
                        color: rgb(255, 255, 255);
                        border-radius: 57px
                    }
                    .cryptoButton{
                        width: 39.46667vw;
                        height: 9.33333vw;
                        border-radius: 6.66667vw;
                        font-size: 4.26667vw;
                        font-weight: 700;
                        color: #fff;
                        background: linear-gradient(1turn,#212529,#212529);
                        box-shadow: 1px 1px 0.8vw #c8c9cc;
                        border: 1px solid #000;
                        margin: 2.66667vw auto 0;
                              }
                    // body {
                    //     background:black url(http://fc01.deviantart.net/fs71/f/2012/160/b/9/stars_by_paulinemoss-d52un4e.jpg);
                    //     animation: stars 250s linear alternate;
                    //   }
                    //   @keyframes stars {
                    //     0% { background-position:0 0 }
                    //     100% { background-position:0 100% }
                    //   }
                    .optionnew {
                        padding: 10px;
                        border-bottom: 1px solid #eee;
                    }
                    .blink_me {
                        animation: blinker 1s linear infinite;
                      }
                      
                      @keyframes blinker {
                        50% {
                          opacity: 0;
                        }
                      }
                    .copy_btn {
                        text-decoration: none;
                        font-size: 16px;
                        background: #FBBD18 !important;
                        padding: 7px 0px;
                        border-radius: 5px;
                        color: #fff;
                        display: block;
                        margin: 0 auto;
                        width: 41%;
                    }

                    .copy_btninvitation {
                        text-decoration: none;
                        font-size: 16px;
                        background: #14d2be !important;
                        padding: 7px 0px;
                        border-radius: 5px;
                        color: #fff;
                        display: block;
                        margin: 0 auto;
                        width: 80%;
                    }
          `
                }}
            />
        </>
    )
}
