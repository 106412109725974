import React from 'react'

export default function NoRecord() {
    return (
        <div className="billddiscription" style={{

            boxShadow: 'rgb(186, 184, 184) 2px 3px 10px', borderRadius: 8, padding: 1, margin: '8px 0px 8px 0px', width: '100%'
        }}>
            <div className='noRecord' style={{color:'#000'}}><img style={{height:21}} src='https://cdn-icons-png.flaticon.com/128/7466/7466140.png'></img> No Records Found..</div>
        </div>

    )
}
