import React from 'react'
import { useNavigate } from "react-router-dom";
export default function BackButton(props) {
    let navigate = useNavigate();
    const routeChange = (path) => {
        navigate('../' + path, { replace: true });
    };
    return (
        <>
            <div>
                <div className='col-12'>
                    <div className='row'>
                        <div className="col-12" style={{ fontSize: '18px', marginTop: '10px', color: '#fff',marginLeft:'34%' }} ><strong>Company</strong></div>
                        <div className='col-12'>

                            <div>
                                <center> <img src="img/logo.png" style={{ width: '71%', marginBottom: 20, height: '90px' }} alt /></center>
                            </div>
                            <p style={{ fontSize: '0.26em', marginBottom: '10px', color: '#fff' }}>
                                ZenGo was launched in 2023 and is one of the leading TRX cloud mining service providers. The purpose of this company is to maximize the interests of each user, so they can leverage large wealth with relatively low capital. “Invest once, enjoy forever,” that’s why all options are recognized and followed by ZenGo. Through future data analysis, users should understand that ZenGo pursues long-term strategic cooperative relationships with users, which is the reason they’ve grown to over 1 million members at this time. Launched in 2018, ZenGo is a cloud mining service built on the Tron network. It provides users with a safe, convenient, and efficient TRX cloud mining experience. Users can visit the official ZenGo website and register for an account with ease. The company enables users to mine the TRX cryptocurrency with a low-cost cloud service. Whether it’s quantitative trading or DeFi technology, ZenGo makes it easy to participate in the blockchain revolution with its cloud mining services. To start cloud mining with ZenGo, users can visit their website, register with an email, deposit TRX and the service will automatically start mining the TRX cryptocurrency for you.
                            </p>
                            <div className="col-12" style={{ fontSize: '20px', marginTop: '10px', color: '#fff' }} ><strong>Our Vision</strong></div>
                            <p style={{ fontSize: '0.26em', marginBottom: '100px', color: '#fff' }}>
                            As the ZenGo team, we work together; to create bigger, stronger things. We are proud to be a family of hundreds of people with experienced businessmen, successful investors, talented engineers, technology gurus, researchers and international user masses. In this job that requires insanity, we do great things with geniuses and dreamers working for the future. We do what we love for the people we love. We are an international financial company engaged in investment activities, which are related to trading on financial markets and cryptocurrency exchanges performed by qualified professional traders. Our goal is to provide our investors with a reliable source of high income, while minimizing any possible risks and offering a high-quality service, allowing us to automate and simplify the relations between the investors and the trustees. We work towards increasing your profit margin by profitable investment. We look forward to you being part of our community.
                            </p>
                            

                        </div>
                    </div>
                </div>
            </div>
            <style
                dangerouslySetInnerHTML={{
                    __html: `
                    body {
                        background:black url(http://fc01.deviantart.net/fs71/f/2012/160/b/9/stars_by_paulinemoss-d52un4e.jpg);
                        animation: stars 250s linear alternate;
                      }
                      @keyframes stars {
                        0% { background-position:0 0 }
                        100% { background-position:0 100% }
                      }
                      /* width */
                        ::-webkit-scrollbar {
                        width: 10px;
                        }

                        /* Track */
                        ::-webkit-scrollbar-track {
                        background: #f1f1f1;
                        }

                        /* Handle */
                        ::-webkit-scrollbar-thumb {
                        background: #888;
                        }

                        /* Handle on hover */
                        ::-webkit-scrollbar-thumb:hover {
                        background: #555;
}
                      
           `
                }}
            />
        </>
    )
}
