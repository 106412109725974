import React, { useState, useEffect } from 'react'
import axios from "axios";
import { Helmet } from "react-helmet";
import BackButton from './BackButton';
import Loading from './Loading';
export default function Reward() {

    const [isLoading, setIsLoading] = useState(false);
    const [rewards, setRewards] = useState([]);
    const BindRewardStatus = () => {
        setIsLoading(true);
        var param = new Object();
        param.ClientId = JSON.parse(localStorage.getItem("UserDetails").hexDecode()).ClientId;
        var obj = new Object();
        obj.procName = 'GetRewardStatus';
        obj.Para = JSON.stringify(param);
        axios({
            method: 'POST',
            url: process.env.REACT_APP_WEBAPI_URL,
            data: obj
        }).then(function (res) {
            setIsLoading(false);
            res = res.data;
            setRewards(res);
        }).catch(function (error) {
            setIsLoading(false);
            console.log(error);
        });
    }
    useEffect(() => {
        BindRewardStatus();
    }, []);
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Reward Status</title>
                <link rel="stylesheet" href="css/myteam.css" />
                <link rel="stylesheet" href="css/bootstrap.min.css" />
                <link rel="stylesheet" href="css/font-awesome.min.css" />
                <script src="js/jquery.min.js"></script>
                <script src="js/bootstrap.min.js"></script>
            </Helmet>
            {isLoading ? <Loading /> : ""}
            <div className="myteam wrap">
                <div className="navbar">
                    <BackButton path='home' />
                    <span style={{ marginRight: '25%', marginTop: '-15px' }}>Reward Status</span>

                </div>
                {/* <div className='certiIcon'><img src="img/jb.png" style={{ height: 70 }} ></img></div> */}
                <div className='card' style={{marginTop: '7%', width: '95%', marginLeft: '11px', marginTop: 10, position: 'fixed', backgroundColor: '#ffe8a4', borderRadius: '40px', paddingBottom: 40 }}>
                    <div className="container" style={{ marginTop: '15px',height:'500px',overflowY:'auto' }}>
                        {/* <h4 className='rewardTxt'>Reward</h4> */}
                        {rewards != "NoRecord" && rewards.map((item, i) => {
                            const OrderList = (
                                <div className='row rowCustom' style={{ height: '93px' }}>
                                    <div className='col-2' style={{ paddingRight: 0, paddingLeft: 0 }}><img src="img/jb.png" style={{ verticalAlign: 'top', width: '100%' }} alt /></div>
                                    <div className='col-7'>
                                        <h5 style={{ fontSize: '13px', marginBottom: 0, color: '#000', marginTop: 5 }}>Required Business: {item.TeamBusiness} TRX</h5>
                                        <h5 style={{ fontSize: '13px', marginBottom: 0, color: '#000', marginTop: 5 }}>My Business: {item.Mybusiness} TRX</h5>
                                        <h5 style={{ fontSize: '13px',marginTop: 5, color: '#000' }}>Bonus Percentage: {item.BonusPercentage}%</h5>
                                    </div>
                                    <div className='col-3 ' style={{ paddingLeft: 0, paddingRight: 0 }}>
                                        <div className='undefined' style={{ background: item.Status == "Pending" ? '#DC3545' : '#28A745' }}>{item.Status}</div>
                                    </div>
                                </div>
                            );
                            return OrderList;
                        })}
                    </div>
                    <i style={{ fontSize: '11px',marginLeft:'10px' }}>Note: For Reward Your direct business will be accumulated only first level business will be considered. After your reward is achieved contact admin to redeem it.</i>
                </div>
            </div>
            <style
                dangerouslySetInnerHTML={{
                    __html: `
                    .myteam .banner {
                        height: 113px;
                    }
            
                    .nav-tabs {
                        display: flex;
                        justify-content: space-between;
                    }
                    .nav-tabs>li>a {
                        margin-right: 2px;
                        line-height: 1.42857143;
                        border: 1px solid transparent;
                        border-radius: 4px 4px 0 0;
                       
                        color: #000;
                        font-size:16px;
                    }
                    .myteam {
                        padding: 0px;
                        background: #fdfdfd;
                        overflow-x: hidden;
                overflow-y: auto;
                height: 425px;
                    }
                    .table tr th{
                        text-align:left !important;
                        font-weight:600 !important;
                        font-size:14px !important;
                    }
                    .table tr td,th{
                        padding:7px !important;
                        text-align:left !important;
                       
                    }
                    .tab-bar {
                        max-width: 750px;
                        left: 50%;
                        -webkit-transform: translateX(-50%);
                        transform: translateX(-50%);
                        position: fixed;
                        bottom: 0px;
                        width: 100%;
                        height: 74px;
                        z-index: 30;
                       
                        padding: 10px 0px;
                        background-size: 100% 100%;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                    }
            
                    .myteam .tips {
                        font-size: .28rem;
                        font-weight: 500;
                        line-height: .45rem;
                        color: #100d0d;
                        background: #ffc107;
                        padding-left: 5px;
                        padding: 5px 8px;
                        margin-bottom: 2px;
                        margin-top: 0px;
                    }
            
                    .tips i {
                        color: #f23e36 !important;
                    }
            
                    .myteam .list li > div {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        font-size: .26rem;
                        font-weight: 400;
                        -webkit-box-pack: center;
                        -ms-flex-pack: center;
                        justify-content: center;
                        color: #262626;
                        margin-top: 0px !important;
                    }
            
                    .myteam .uls {
                        padding-left: 0px;
                    }
            
                    .myteam .list li span {
                        width: auto;
                        height: auto;
                        background: #f5fff700;
                        border-radius: 0.2rem;
                        font-size: .28rem;
                        font-weight: 500;
                        line-height: 10px;
                        color: #03296c;
                        margin-top: 0.08rem;
                        text-align: center;
                    }
            
                    .myteam .list li {
                        border-bottom: 1px solid #EEEEF0;
                        width: 47%;
                        list-style: none;
                    }
            
                    .myteam .uls li div:nth-child(2) p {
                        margin: 0px;
                    }
            
                    .myteam .uls li:nth-child(3) {
                        border-top: 1px solid #EEEEF0;
                        border-bottom: 1px solid #EEEEF0;
                    }
            
                    .my-item {
                        padding: 10px;
                        display: flex;
                        font-size: 14px;
                        border-bottom: 1px solid #f2f2f2;
                        background: #ffff;
                    }
            
                        .my-item img {
                            width: 20px;
                            height: 20px;
                        }
            
                        .my-item div {
                            padding-left: 20px;
                            width: 90%;
                            color: #000;
                        }
            
                        .my-item span {
                            width: 21px;
                            background-image: url(./img/arrow4.png);
                            background-size: 100%;
                            background-repeat: no-repeat;
                            filter: invert(1);
                            height: 20px;
                display: block;
                        }
            
                    .nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
                        color: #000;
                        cursor: default;
                        font-weight:bold;
                        border: none;
                        background-color: transparent;
                        border-bottom: 2px solid #000;
                        border-bottom-color: #000;
                        text-decoration:none;
                    }
                    .nav-tabs{
                        border:0px !important;
                    }
                    .nav-tabs li{
                        line-height:20px;
                    }
            
                    .time[data-v-b7e8b406] {
                        overflow: hidden;
                        padding: 5px;
                        line-height: 0.8rem;
                        border-bottom: 0.013333rem solid #eee;
                    }
            
                    .time p[data-v-5a05e720] {
                        float: left;
                        background: #f7f7f700;
                        border-radius: 0.4rem;
                        padding: 0 0.666667rem;
                        font-size: 0.32rem;
                    }
            
                    .time span[data-v-5a05e720] {
                        float: left;
                        padding: 0 0.266667rem;
                        font-size: 0.373333rem;
                    }
            
                    .search button[data-v-b7e8b406] {
                        background-color:#4D148C!important;
                        background-size: 0.4rem;
                        border-radius: 0.4rem;
                        padding: 0 0.266667rem 0 0.8rem;
                        line-height: 0.8rem;
                        float: right;
                        color: #fff;
                        font-size: 0.346667rem;
                        border: none;
                    }
                    .dataTables_info {
                       margin-bottom: 10px!important;
                    }
                    .previous {
                        padding: 3px 5px;
                background: #124296;
                color: #fff;
                border-radius: 4px;
                    }
                    .next {
                         padding: 3px 5px;
                background: #124296;
                color: #fff;
                border-radius: 4px;
                    }
          `
                }}
            />
        </>
    )
}
