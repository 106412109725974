import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Loading from './Loading';
import { Helmet } from "react-helmet";
import { toast } from 'react-toastify';
export default function CreateAccount() {
    let navigate = useNavigate();
    let params = (new URL(document.location)).searchParams;

    const routeChange = (path) => {
        navigate('../' + path, { replace: true });
    };
    const inputEls = useRef([]);
    //Handling Loading 
    const [isLoading, setIsLoading] = useState(false);
    //State Management
    const [allValues, setAllValues] = useState({
        Name: 'ZenGo',
        MobileNo: '',
        Password: '',
        ConfirmPassword: '123456',
        TransactionPassword: '123456',
        SponsorId: params.get("ref") ? params.get("ref") : ""
    });
    const changeHandler = e => {
        setAllValues({ ...allValues, [e.target.name]: e.target.value })
    }

    const doRegistration = () => {
        // if (allValues.Name == "") {
        //     toast.error('Enter Nick Name');
        //     inputEls.current[4].focus();
        //     return;
        // }
        if (allValues.MobileNo == "") {
            toast.error('Enter Email Id.');
            inputEls.current[1].focus();
            return;
        }
        // if (allValues.MobileNo.toString().length < 10 || allValues.MobileNo.toString().length > 15) {
        //     toast.error('Enter Valid Email Id.');
        //     inputEls.current[1].focus();
        //     return;
        // }
        if (allValues.Password == "") {
            toast.error('Enter Password.');
            inputEls.current[2].focus();
            return;
        }
        // if (allValues.ConfirmPassword == "") {
        //     toast.error('Enter Confirm Password.');
        //     inputEls.current[3].focus();
        //     return;
        // }
        // if (allValues.ConfirmPassword != allValues.Password) {
        //     toast.error('Password and Confirm Password do not matched.');
        //     inputEls.current[3].focus();
        //     return;
        // }
        // if (allValues.TransactionPassword == "") {
        //     toast.error('Enter Withdrawal Password.');
        //     inputEls.current[4].focus();
        //     return;
        // }
        if (allValues.SponsorId == "") {
            toast.error('Enter Referral Code.');
            inputEls.current[5].focus();
            return;
        }
        setIsLoading(true);
        var param = new Object();
        param.Name = allValues.Name;
        param.SponsorUserName = allValues.SponsorId;
        param.Password = allValues.Password;
        param.ConfirmPassword = allValues.ConfirmPassword;
        param.TransactionPassword = allValues.TransactionPassword;
        param.ConfirmTransactionPassword = allValues.TransactionPassword;
        param.MobileNo = allValues.MobileNo;
        var obj = new Object();
        obj.procName = 'TronZRegistration';
        obj.Para = JSON.stringify(param);
        axios({
            method: 'POST',
            url: process.env.REACT_APP_WEBAPI_URL,
            data: obj
        }).then(function (res) {
            setIsLoading(false);
            res = res.data;
            if (res[0].StatusCode == "1") {
                toast.success('Success');
                var UserDetails = JSON.stringify(res[0]);
                UserDetails = UserDetails.hexEncode();
                localStorage.setItem("UserDetails", UserDetails);
                setTimeout(function () {
                    routeChange('home');
                }, 1000)
            }
            else {
                toast.error(res[0].Msg);
            }
        }).catch(function (error) {
            setIsLoading(false);
        });
    }

    const [passwordType, setPasswordType] = useState("password");
    const [ConfirmpasswordType, setConfirmPasswordType] = useState("password");
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }
    const toggleConfirmPassword = () => {
        if (ConfirmpasswordType === "password") {
            setConfirmPasswordType("text")
            return;
        }
        setConfirmPasswordType("password")
    }
    return (

        <>
            {isLoading ? <Loading /> : ""}
            <Helmet>
               
                <link href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" />
            </Helmet>
            <div className="form">
                <img src='img/logo.png' className='logo' />
                <div className="input">
                    <div className="inputBox">
                        <label htmlFor>Email Id<span style={{fontSize:10,color:'red'}}></span></label>
                        <input id="txtMobileNo" ref={(el) => (inputEls.current[1] = el)} onChange={changeHandler} type="text" name="MobileNo" placeholder='Enter Email Id' />
                    </div>
                    <div className="inputBox">
                        <label htmlFor>Password<span style={{fontSize:10,color:'red'}}></span></label>
                        <input id="txtPassword" ref={(el) => (inputEls.current[2] = el)} onChange={changeHandler} name="Password" type="password" placeholder='Enter Password' />
                    </div>
                    <div className="inputBox">
                        <label htmlFor>Sponsor Code</label>
                        <input value={allValues.SponsorId ? allValues.SponsorId : ""} id="txtSponsorId" name="SponsorId" ref={(el) => (inputEls.current[5] = el)} onChange={changeHandler} type="text" placeholder='Enter Sponsor Code' />
                    </div>
                    <div className="inputBox">
                        <input type="button" style={{background:'#606670'}} name defaultValue="Submit" onClick={doRegistration} />
                    </div>
                </div>
                <p className="forgot">Already a member? <a href="#" onClick={() => routeChange("auth")}>Login Now</a></p>

            </div>
            <style
                dangerouslySetInnerHTML={{
                    __html: `
                    @import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Oswald', sans-serif;
}
.gradient-border {
    --borderWidth: 2px;
    background: #ffffff00;
    position: relative;
    border-radius: var(--borderWidth);
}
body {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #060c21;
}

.form {
    position: relative;
    background: #5EE9C7;
   // border: 1px solid #ffc107;
    width: 350px;
    padding: 0px 40px 60px;
    border-radius: 10px;
    text-align: center;
}

.form::before {
    content: '';
    position: absolute;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
    background: linear-gradient(315deg, #ffffff, #ffffff);
    z-index: -1;
    transform: skew(2deg, 1deg);
    border-radius: 10px;
}

.form h2 {
    color: #fff;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 5px;
}

.form .input {
    margin-top: 40px;
    text-align: left;
}

.form .input .inputBox {
    margin-top: 10px;
}

.form .input .inputBox label {
    display: block;
    color: #fff;
    margin-bottom: -31px;
    font-size: 18px;
    letter-spacing: 1px;
}

.form .input .inputBox input {
    position: relative;
    width: 100%;
    height: 40px;
    border: none;
    outline: none;
    padding: 5px 15px;
    //background: linear-gradient(315deg, #f4a908, #D4BA3D)!important;
    color: #000;
    font-size: 18px;
    border-radius: 10px;
}

.form .input .inputBox input[type="submit"] {
    cursor: pointer;
    margin-top: 20px;
    letter-spacing: 1px;
}

.form .input .inputBox input[type="submit"]:hover {
    //background: linear-gradient(315deg, #f4a908, #D4BA3D);
}

.form .input .inputBox input[type="submit"]:active {
    color: rgba(255, 255, 255, 0.521);
    //background: linear-gradient(315deg, #e91e6271, #5f02ff8c);
}

.forgot {
    margin-top: 10px;
    color: #495057;
    font-size: 14px;
    letter-spacing: 1px;
}

.forgot a {
    color: #495057;
}

.social {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.social button {
    width: 75%;
    border-radius: 20px;
    margin-bottom: 15px;
    padding: 5px 10px;
    background: transparent;
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;

}

.social button i {
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: #fff; */
    width: 20px;
    height: 20px;
    border-radius: 50%;

}

.social button p {
    color: #fff;
    font-size: 15px;
    margin-left: 10px;
    letter-spacing: 1px;
}

.social button:hover {
    background: linear-gradient(315deg, #326cd6, #5d02ff);
}

.social button:hover i {
    filter: invert(1);
}

.social button:active {
    background: linear-gradient(315deg, #f4a908, #71A834)
}
                    ::placeholder {
                        color: #000 !important;
                    }
                    .logo {
                        width: 235px;
                        height:68px;
                        margin: auto;
                       
                    }
                    /* Chrome, Safari, Edge, Opera */
                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                    }

                    /* Firefox */
                    input[type=number] {
                    -moz-appearance: textfield;
                    }
                    
          `
                }}
            />
        </>
    )
}
