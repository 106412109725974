import React from 'react'
import { Helmet } from "react-helmet";
export default function Company() {
    return (
        <>
        <Helmet>
                <meta charSet="utf-8" />
                <title>About Us</title>
                <link rel="stylesheet" href="css/Aboutus.css" />
            </Helmet>
            <div className="about">
                <div className="banner" style={{ padding: 15 }}>
                    <img src="img/logo.png" alt height={50} width={40} />
                </div>
                <div className="content-container bg" style={{ marginTop: '-45px' }}>
                    {/* <div>
                        <img src="img/p3.jpg" style={{ width: '100%' }} />
                    </div> */}
                    <h3 style={{ fontSize: 14, color: '#000!important' }}>Our Company
                    </h3>
                    <p style={{ color: '#FF6600!important' }}>
                    Tron Miner was launched in 2022 and is one of the leading TRX cloud mining service providers. The purpose of this company is to maximize the interests of each user, so they can leverage large wealth with relatively low capital. “Invest once, enjoy forever,” that’s why all options are recognized and followed by Tron Miner. Through future data analysis, users should understand that Tron Miner pursues long-term strategic cooperative relationships with users, which is the reason they’ve grown to over 1 million members at this time. Launched in 2018, Tron Miner is a cloud mining service built on the Tron network. It provides users with a safe, convenient, and efficient TRX cloud mining experience. Users can visit the official Tron Miner website and register for an account with ease. The company enables users to mine the TRX cryptocurrency with a low-cost cloud service. Whether it’s quantitative trading or DeFi technology, Tron Miner makes it easy to participate in the blockchain revolution with its cloud mining services. To start cloud mining with Tron Miner, users can visit their website, register with an email, deposit TRX and the service will automatically start mining the TRX cryptocurrency for you.
                    </p>
                    
                </div>
            </div>
            <style
                dangerouslySetInnerHTML={{
                    __html: `
                    .about .footer {
                        padding: 15px 0;
                        background-color: #F5F6FA;
                        /* margin-bottom: 64px; */
                        padding-bottom: 60px;
                    }
            
                    .about .content-container p {
                        font-size: 15px;
                        font-weight: 400;
                        line-height: 1.2;
                        color: #03296c;
                        margin-bottom:0!important;
                       
                    }
            
                    .about .content-container h2 {
                        text-align: left;
                        color: #333333;
                        font-size: 50px;
                        padding: 0px;
                        -webkit-transform: scaleX(0.8);
                        transform: inherit;
                        line-height: normal;
                    }
          `
                }}
            />

        </>
    )
}
