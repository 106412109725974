import React from 'react'
import NoRecord from './NoRecord';
export default function IncomeCard(props) {

    return (
        <>
            {props.data != null ? props.data.map((item, i) => {
                const PackageList = (
                    // <div className="billddiscription" style={{
                    //     display: 'flex', justifyContent: 'space-between', boxShadow: '2px 3px 10px #bab8b8',
                    //     borderRadius: 8, padding: 3,borderRadius: 76, margin: '12px 0px', marginLeft: 4, width: '100%'
                    // }}>
                    //     <div><p style={{ margin: '7px 0px', fontSize: 12, color: '#fff' }} className="TransactionNote">
                    //         <img src="https://cdn-icons-png.flaticon.com/512/2953/2953423.png" height="25px" style={{ margin: '5px 0px', fontSize: 12, color: '#fff',marginLeft: '20px' }} />
                    //         <span style={{ fontSize: 14, position: 'absolute', marginTop: 5, marginLeft: 7 }} className="UserMobileNo">{item.TransactionNote}</span>
                    //         <span style={{ fontSize: 14, position: 'absolute', marginTop: 5, marginLeft: 230 }} className="UserMobileNo"><span>{item.Amount}</span></span>

                    //         <span style={{ fontSize: 13, position: 'absolute', marginTop: 25, marginLeft: 230 }} className="UserMobileNo"><span>{item.Status}</span></span>
                    //     </p>
                    //         <h6 style={{ margin: '5px 0px', fontSize: 12, color: '#fff',marginLeft: '20px' }} className="TransactionDate">{item.TransactionDate}</h6>

                    //     </div>
                    //     <div className="billnumber" style={{ marginRight: 7 }}><h3 style={{ margin: '16px 0px', fontSize: 15, color: '#000' }}
                    //         className="TransactionAmount"></h3></div>

                    // </div>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='grayblkmy' style={{background:'#55EFC0',backgroundPosition:'left%'}}>
                                <div style={{ height: '25px', }}><span className='normaltxt' style={{ color: '#000',fontSize:11,fontWeight:'normal' }}>{item.TransactionNote} ({item.TransactionDate})</span></div>
                                <div className='textFigure'><span style={{ color: '#000', fontSize: 15 }}>{item.Amount}</span> </div>
                                <div className='textFigure otherSpan'><span style={{ color: '#000',fontSize:12 }}>{item.Status} </span> </div>
                            </div>
                            <img className='myicon' src='https://cdn-icons-png.flaticon.com/512/4149/4149717.png' style={{ height: '50px' }}></img>
                        </div>
                    </div>
                );
                return PackageList;
            }) : <NoRecord />}
        </>
    );
}
