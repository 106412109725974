import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import BackButton from './BackButton';
import axios from "axios";
import Loading from './Loading';
export default function Profile() {
    let navigate = useNavigate();
    const routeChange = (path) => {
        navigate('../' + path, { replace: true });
    };
    //Handling Loading 
    const [isLoading, setIsLoading] = useState(false);

    const [profileInfo, setProfile] = useState({
        mobileNo: "",
        inviteCode: "",
        totalInvestment: 0,
        totalEarning: 0,
        currentBalance: 0,
    })

    const GetProfileDetails = () => {
        setIsLoading(true);
        var param = new Object();
        param.ClientId = JSON.parse(localStorage.getItem("UserDetails").hexDecode()).ClientId;
        var obj = new Object();
        obj.procName = 'GetMemberBasicDetails';
        obj.Para = JSON.stringify(param);
        axios({
            method: 'POST',
            url: process.env.REACT_APP_WEBAPI_URL,
            data: obj
        }).then(function (res) {
            setIsLoading(false);
            res = res.data;
            //Setting  Details
            let xCode = JSON.parse(localStorage.getItem("UserDetails").hexDecode());
            setProfile({ mobileNo: xCode.ContactNo, inviteCode: xCode.UserName, 
                totalInvestment: res[0].TotalInvestment, 
                totalEarning: res[0].TotalEarning, 
                currentBalance: res[0].WalletAmount });

        }).catch(function (error) {
            setIsLoading(false);
            console.log(error);
        });
    }
    const LogOut = () => {
        localStorage.clear();
        routeChange("login");
    }
    useEffect(() => {
        GetProfileDetails();
    }, []);
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Profile</title>
                <link rel="stylesheet" href="css/myteam.css" />
            </Helmet>
            {isLoading ? <Loading /> : ""}
            <div className="myteam wrap">
                
                <div className="banner banner2">
                    <div>
                        <div className="portrait" style={{ marginBottom: 20, marginTop: 20 }}>
                            <img src="img/logo.png" alt />
                        </div>
                        <div className="username">
                            {/*				<h3>User name</h3>*/}
                            <h3 style={{ marginBottom: 0 }}><span className="UserMobileNo">{profileInfo.mobileNo}</span></h3>
                            <p style={{ marginTop: 0 }}>Invite Code:&nbsp;<span className="inviteCode">{profileInfo.inviteCode}</span></p>
                        </div>
                    </div>
                </div>
                <div className="unclamed" style={{ marginTop: '-62px' }}>
                    <div className="insurancenew" style={{ background: '#000', borderRadius: '25px', display: 'flex', justifyContent: 'space-around', padding: 10, paddingTop: 16 }}>
                        <div className="insuranceone">
                            <h5 style={{ margin: 0, fontSize: 13, color: '#fff', textAlign: 'center' }} className="Investment">{profileInfo.totalInvestment} USDT
                            </h5>
                            <p style={{ margin: 0, fontSize: 12, color: '#fff' }}>
                                Total Order Value
                            </p>
                        </div>
                        <div style={{ borderLeft: '1px solid #eee' }}>
                        </div>
                        <div className="insuranceone">
                            <h5 style={{ margin: 0, fontSize: 13, color: '#fff', textAlign: 'center' }} className="ROIIncome">{profileInfo.currentBalance} USDT
                            </h5>
                            <p style={{ margin: 0, fontSize: 12, color: '#fff' }}>
                                Wallet Balance
                            </p>
                        </div>
                        <div style={{ borderLeft: '1px solid #eee' }}>
                        </div>
                        <div className="insuranceone">
                            <h5 style={{ margin: 0, fontSize: 13, color: '#fff', textAlign: 'center' }} className="walletBalance">{profileInfo.currentBalance} USDT
                            </h5>
                            <p style={{ margin: 0, fontSize: 12, color: '#fff' }}>
                                Total Earning
                            </p>
                        </div>
                    </div>
                </div>
                {/* <div className="portrait" style={{ marginBottom: -19, marginTop: 20 }}>
                    <img src="img/promotionimage.png" style={{ height: '72px', width: '88%', marginLeft: '27px' }} />
                </div> */}

                <ul className="menulist" style={{ marginTop: 17 }}>
                    <li onClick={() => routeChange('bindbank')}>
                        <img src="img/5566925.png" alt />
                        <div>Update Withdraw Method</div>
                        <i />
                    </li>
                    <li onClick={() => routeChange('order')}>
                        <img src="img/order.png" alt />
                        <div>Order History</div>
                        <i />
                    </li>
                    <li onClick={() => routeChange('income')}>
                        <img src="img/statement.png" alt />
                        <div>Revenue Records</div>
                        <i />
                    </li>

                    <li onClick={() => routeChange('changePassword')}>
                        <img src="img/pwd.png" alt />
                        <div>Change Password</div>
                        <i />
                    </li>
                    {/* <li onClick={() => routeChange('changePassword')}>
                        <img src="img/3500716.png" alt />
                        <div>Change Transaction Password</div>
                        <i />
                    </li> */}
                    <li onClick={() => window.location.href = 'https://t.me/Solarfundsmanager'}>
                        <img src="img/8084156.png" alt />
                        <div>Support</div>
                        <i />
                    </li>
                    <li onClick={() => routeChange('invite')}>
                        <img src="img/2474908.png" alt />
                        <div>Invite</div>
                        <i />
                    </li>
                    {/* <li onClick={() => routeChange('company')}>
                        <img src="img/818441.png" alt />
                        <div>About Us</div>
                        <i />
                    </li> */}
                </ul>
                <div className="logoutbtn" style={{ textAlign: 'center', marginTop: '-15px' }}>
                    <a href="javascript:void(0);" style={{ textDecoration: 'none', padding: '5px 48px', fontSize: 17 }} className="btn" onClick={LogOut}><i className='fa fa-power-off'></i>&nbsp;Exit</a>
                </div>
            </div>

            <style
                dangerouslySetInnerHTML={{
                    __html: `
                    .myteam .menulist li {
                        height: 50px;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        font-size: 16px;
                        font-weight: bold;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        position: relative;
                        border-bottom: 1px solid #fff;
                    }
        
                    .lease .notice {
                        background: #fff;
                        height: 122px;
                        background: #FFFFFF;
                        border-radius: 0.2rem;
                        margin-top: -2.4rem;
                    }
        
                    .myteam .menulist {
                        background: #efb94354;
                        padding: 0 0.2rem;
                        border-radius: 0.2rem;
                        margin-bottom: 0px;
                    }
        
                    .lease {
                        background: #F5F6F7;
                        min-height: auto;
                        margin-top: 134px;
                        margin-bottom: 13px;
                        padding: 10px 0px;
                    }
        
                    .lease .notice>ul {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -ms-flex-wrap: wrap;
                        flex-wrap: wrap;
                        background: #fff;
                        border-radius: 8px;
                    }
        
                    .myteam .list.list2 li div {
                        font-size: .26rem;
                        font-weight: 400;
                        color: #2FCC58;
                        text-align: left;
                        display: block;
                        width: 100%;
                        margin-top: 0px !important;
                        border-right: 1px solid #eee;
                    }
        
                    .myteam .list.list2 li p {
                        font-size: .26rem;
                        font-weight: 500;
                        margin-top: -2px;
                        color: #333333;
                        text-align: left;
                        width: 100%;
                    }
        
                    .myteam .menulist li i {
                        width: 0.4rem;
                        height: 0.4rem;
                        background: url(./img/arrow4.png) no-repeat;
                        background-size: 100% 100%;
                        position: absolute;
                        right: 0;
                        top: 50%;
                        -webkit-transform: translateY(-50%);
                        transform: translateY(-50%);
                        filter: invert(1);
                    }
        
                    a,
                    p,
                    h1,
                    h2,
                    h3,
                    h4,
                    h5,
                    h6,
                    span,
                    li,
                    div {
                        font-family: Microsoft YaHei !important;
                    }
        
                    .btn {
                        width: 5.5rem;
                        height: 34px;
                        background: #fff;
                        border-radius: 23px;
                        font-size: .36rem;
                        font-weight: 400;
                        line-height: .88rem;
                        color: #fff;
                        background:#5cb85c;
                        cursor: pointer;
                        text-align: center;
                        margin: 0.6rem auto 0.3rem auto;
                        list-style: none;
                        border: none;
                        border: 2px solid none;
                    }
        
                    .myteam .list.list2 {
                        margin-top: -26px;
                        padding: 0.23rem 0;
                        margin-bottom: 10px;
                        text-align: center;
                        justify-content: center;
                        height: 20px;
                    }
        
                    .myteam .list.list2 li {
                        display: block;
                        padding-left: 14px;
                        border-bottom: none;
                        width: auto;
                        /* height: 10px; */
                        text-align: center;
                        justify-content: center;
                    }
        
                    .myteam .list {
                        width: 100%;
                        background: #FFFFFF;
                        border-radius: 0.2rem;
                        margin-top: -2.25rem;
                        min-height: 72px;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -ms-flex-wrap: wrap;
                        flex-wrap: unset;
                        padding: 0.23rem 0.22rem 0.23rem 0.25rem;
                    }
        
                    .myteam .list li {
                        border-left: none !important;
                    }
        
                    .myteam .list.list2 li div {
                        font-size: .26rem;
                        font-weight: 400;
                        color: #2FCC58;
                        text-align: left;
                        display: block;
                        width: 100%;
                        margin-top: 0px !important;
                        text-align: center;
                    }
        
                    .myteam .list.list2 li p {
                        font-size: .26rem;
                        font-weight: 500;
                        margin-top: -2px;
                        color: #333333;
                        text-align: left;
                        width: 100%;
                        text-align: center;
                    }
        
                    .myteam .list.list2 li:nth-child(2n) {
                        width: auto;
                        border-left: none;
                    }
        
                    .myteam .banner.banner2 {
                        height: 180px;
                        background: #FF6600!important;
                        background-size: 100% auto;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        -webkit-box-pack: justify;
                        -ms-flex-pack: justify;
                        justify-content: space-between;
                        padding: 0 0.26rem 0 0.65rem;
                    }
        
                    .myteam {
                        background: #F5F6F7;
                        min-height: 0vh;
                        padding: 0 0.26rem 1.8rem 0.26rem;
                    }
        
                    .twobtn {
                        display: flex;
                        justify-content: space-around;
                        margin-top: -24px;
                    }
        
                    .rechbtn a {
                        text-decoration: none;
                        font-size: 16px;
                        background: linear-gradient(0deg, #fb3273, #fa6a6a);
                        padding: 4px 20px;
                        border-radius: 50px;
                        color: #fff;
                    }
        
                    .widhbtn a {
                        text-decoration: none;
                        font-size: 16px;
                        background: linear-gradient(0deg, #4375fc, #489cfb);
                        padding: 4px 20px;
                        border-radius: 50px;
                        color: #fff;
                    }
        
                    .custom_2_grid {
                        padding: 10px 0;
                    }
        
                    .inner_grid {
                        /* min-height: 100px; */
                        border-radius: 15px;
                        padding: 10px;
                        text-align: center !important;
                        height: 50%;
                    }
        
                    .grid_header_text {
                        margin: 0;
                        color: #fff;
                        font-size: 20px;
                        text-align: center;
                    }
        
                    .grid_header_text i {
                        color: #fff;
                        border-radius: 50%;
                        height: 40px;
                        width: 40px;
                        padding: 0px;
                        font-size: 30px;
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        border: 2px solid;
                    }
        
                    .small_text {
                        font-size: 14px;
                        margin-top: -2px;
                        color: #000;
                        display: block;
                        text-align: center;
                        font-family: Microsoft YaHei !important;
                    }
        
                    .inner_grid p {
                        font-size: 16px;
                        color: #fff;
                    }
        
                    .inner_grid p span {
                        display: inline-block;
                        padding-right: 15px;
                    }
        
                    .price_balance {
                        font-weight: 600;
                    }
          `
                }}
            />
        </>
    )
}
