import React, { useState, useEffect, useRef } from 'react'
import axios from "axios";
import Loading from './Loading';
import { Helmet } from "react-helmet";
import BackButton from './BackButton';
import Tabs from './Tabs';

export default function Plans() {
    const [isLoading, setIsLoading] = useState(false);
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Plans</title>
                <link rel="stylesheet" href="css/Recharge.css" />
                <link rel="stylesheet" href="css/lease.css" />
                <link rel="stylesheet" href="css/font-awesome.min.css" />
            </Helmet>
            {isLoading ? <Loading /> : ""}
            <div class="recharge-wrap" style={{ backgroundColor: '#fff',minHeight:'119vh' }}>
                <div className="navbar">
                    <BackButton path='home' />
                    <span style={{ marginRight: '25%', marginTop: '-15px',fontSize:15 }}>ZenGo Mining Packages</span>

                </div>
                <div className='row myrow'>
                    <div className='col-12'>
                        <Tabs />
                    </div>
                </div>
            </div>
            <style
                dangerouslySetInnerHTML={{
                    __html: `
                    .myrow{
                        margin-left:0px!important;
                        margin-right:0px!important;
                      }
                    .mb-1, .my-1{
                        display:none!important;
                    }
`
                }}
            />
        </>
    )
}
