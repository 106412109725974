import React from 'react'
import QRCode from 'react-qr-code';
import { useState, useEffect } from 'react';
import axios from "axios";
import { Helmet } from "react-helmet";
import Loading from './Loading';
import { toast } from 'react-toastify';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import BackButton from './BackButton';

export default function DepositUSDT() {
    let coinType = 'USDT(TRC20)';
    const [value, setValue] = useState("");
    const [trxvalue, setTRXValue] = useState("");
    const [back, setBack] = useState('#ffffff');
    const [fore, setFore] = useState('#00000');
    const [size, setSize] = useState(200);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        GetDEpositAddress();
        //GetTRXDEpositAddress();
    }, []);
    const GetDEpositAddress = () => {
        setIsLoading(true);
        var param = new Object();
        param.ClientId = JSON.parse(localStorage.getItem("UserDetails").hexDecode()).ClientId;
        param.Type = coinType;
        axios({
            method: 'POST',
            url: process.env.REACT_APP_API_URL + "GenerateDepositAddress",
            data: param
        }).then(function (res) {
            setIsLoading(false);
            res = res.data;
            setValue(res[0].Msg);
        }).catch(function (error) {
            setIsLoading(false);
        });
    }
    const GetTRXDEpositAddress = () => {
        setIsLoading(true);
        var param = new Object();
        param.ClientId = JSON.parse(localStorage.getItem("UserDetails").hexDecode()).ClientId;
        param.Type = coinType;
        axios({
            method: 'POST',
            url: process.env.REACT_APP_API_URL + "GenerateDepositAddress",
            data: param
        }).then(function (res) {
            setIsLoading(false);
            res = res.data;
            setTRXValue(res[0].Msg);
        }).catch(function (error) {
            setIsLoading(false);
        });
    }
    const CopyCallBack = (t, r) => {
        if (r == true) {
            toast.success("Deposit Address Copied!!");
        }
    }
    return (
        <>

            <Helmet>
                <meta charSet="utf-8" />
                <title>My Team</title>
                <link rel="stylesheet" href="css/myteam.css" />
                <link rel="stylesheet" href="css/bootstrap.min.css" />
                <link rel="stylesheet" href="css/font-awesome.min.css" />
                <script src="js/jquery.min.js"></script>
                <script src="js/bootstrap.min.js"></script>
            </Helmet>
            {isLoading ? <Loading /> : ""}
            <div className="recharge-wrap recharge-wrap2" style={{ minHeight: '110vh' }}>
                <div className="navbar">
                    <BackButton path='home' />
                    <span style={{ marginRight: '25%', marginTop: '-15px' }}>Deposit By USDT</span>

                </div>
                <div className=" list-wrap3" style={{ margin: 5 }}>
                    <ul className="nav nav-tabs" style={{display:'none'}}>
                        <li className="active"><a data-toggle="tab" href="#TRX">TRX</a></li>
                        {/* <li><a data-toggle="tab" href="#USDT">USDT</a></li> */}
                    </ul>
                    <div className="tab-content">

                        <div id="TRX" className="tab-pane fade in active" style={{ minHeight: 370, opacity: 1, overflowX: 'hidden', overflowY: 'auto' }}>

                            <div className="billdeailsnew" style={{ marginTop: '-40px' }}>
                                <div className="billlist">
                                    <div className="billddiscription" style={{ borderRadius: 0, margin: '55px 15px' }}>
                                        <div className="invoce_header myheader">
                                            {/* <p style={{ margin: 0, fontSize: 17, color: '#fff', padding: '0px 0', textAlign: 'center' }}>
                                                Network: TRON
                                            </p> */}

                                            <p className='blink_me1' id="refUsername" style={{ margin: 0, fontSize: 21, color: '#fff', padding: '0px 0 15PX', textAlign: 'center', fontWeight: 600 }}>
                                            <b>USDT (TRC20 Chain)</b> Deposit
                                            <span style={{fontSize:15,color:'#FBBD18'}}> <br></br>Exchange Rate</span>
                                           <br></br> 1 USDT=15 TRX 
                                           <span style={{fontSize:15,color:'#FBBD18'}}> <br></br>Minimum Deposit: 5 USDT</span>
                                            </p>
                                        </div>
                                        <div className style={{ textAlign: 'center', padding: '10px 15px 25px' }}>
                                            <p style={{ margin: 0, fontSize: 17, color: '#fff', padding: '15px 0', textAlign: 'center' }}>
                                                Scan <b>USDT</b> QR Code
                                            </p>

                                            <QRCode
                                                title="$tox"
                                                value={value}
                                                bgColor={back}
                                                fgColor={fore}
                                                size={size === '' ? 0 : size}
                                            />
                                            <input value={value} type="text" style={{ width: '83%', borderRadius: '0.133333rem', fontSize: 12, border: 'none', marginTop: 5, marginBottom: 10, marginLeft: 0, height: '0.8rem', background: '#fff', paddingLeft: '0.4rem', fontWeight: 700, color: '#000!important' }} id="refLink" />

                                            <CopyToClipboard text={value} onCopy={CopyCallBack}>
                                                <button className="copy_btn" style={{ border: 'none' }}><i className='fa fa-copy'></i> Copy</button>
                                            </CopyToClipboard>
                                            <p style={{ color:'#FBBD18',fontSize: 12, fontStyle: 'italic', marginTop: 5 }}>Important Notice: Please make sure you're only depositing USDT(TRC20 Chain TRON Network) to above deposit address TRX payments are not allowed, minimum deposit is 5 USDT less than 5 USDT payment will not be confimed.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                       
                    </div>


                </div>
            </div>
            <style
                dangerouslySetInnerHTML={{
                    __html: `
                    .myheader{
                        background: #212529;
                        color: rgb(255, 255, 255);
                        border-radius: 57px
                    }
                    body {
                        background:black url(http://fc01.deviantart.net/fs71/f/2012/160/b/9/stars_by_paulinemoss-d52un4e.jpg);
                        animation: stars 250s linear alternate;
                      }
                      @keyframes stars {
                        0% { background-position:0 0 }
                        100% { background-position:0 100% }
                      }
                    .optionnew {
                        padding: 10px;
                        border-bottom: 1px solid #eee;
                    }
                    .blink_me {
                        animation: blinker 1s linear infinite;
                      }
                      
                      @keyframes blinker {
                        50% {
                          opacity: 0;
                        }
                      }
                    .copy_btn {
                        text-decoration: none;
                        font-size: 16px;
                        background: #FBBD18 !important;
                        padding: 7px 0px;
                        border-radius: 5px;
                        color: #fff;
                        display: block;
                        margin: 0 auto;
                        width: 70%;
                    }
                    .nav-tabs {
                        display: flex;
                        justify-content: space-evenly;
                    }
                    .nav-tabs>li>a {
                        margin-right: 2px;
                        line-height: 1.42857143;
                        border: 1px solid transparent;
                        border-radius: 4px 4px 0 0;
                        border-bottom: 2px solid #ffc107;
                        color: #000;
                        font-size:17px;
                        padding: 10px;
                    }
                    .nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
                        color: #fff;
                        cursor: default;
                        font-weight: bold;
                        border: none;
                        background-color: transparent;
                        border-bottom: 2px solid #007bff;
                        border-bottom-color: #007bff;
                        text-decoration: none;
                        background: #F76300 !important;
                        border-bottom: 2px solid green;
                    }
                    .nav-tabs{
                        border:0px !important;
                    }
                    .nav-tabs li{
                        line-height:20px;
                    }
          `
                }}
            />
        </>
    )
}
