import React, { useState, useEffect, useRef } from 'react'
import axios from "axios";
import Loading from './Loading';
import QRCode from 'react-qr-code';
import { toast } from 'react-toastify';
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { CopyToClipboard } from 'react-copy-to-clipboard';
export default function PayNow() {

    const [isLoading, setIsLoading] = useState(false);
    const { state } = useLocation();
    const [value, setValue] = useState("");
    var endPoint = "";
    const [back, setBack] = useState('#fff');
    const [fore, setFore] = useState('#000');
    const [size, setSize] = useState(150);

    const { coinType, amount,coinImage } = state; // Read values passed on state
    useEffect(() => {
        GetDepositAddress();
    }, []);
    const GetDepositAddress = () => {
        setIsLoading(true);
        endPoint = coinType == "TRX" ? "GenerateDepositAddress" : "GenerateDepositAddress";
        var param = new Object();
        param.ClientId = JSON.parse(localStorage.getItem("UserDetails").hexDecode()).ClientId;
        param.Type = coinType;
        axios({
            method: 'POST',
            url: process.env.REACT_APP_API_URL + endPoint,
            data: param
        }).then(function (res) {
            setIsLoading(false);
            res = res.data;
            setValue(res[0].Msg);
        }).catch(function (error) {
            setIsLoading(false);
        });
    }

    const CopyCallBack = (t, r) => {
        if (r == true) {
            toast.success("Copied");
        }
    }

    return (

        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Recharge</title>
                <link rel="stylesheet" href="css/Recharge.css" />
                <link rel="stylesheet" href="css/lease.css" />
                <link rel="stylesheet" href="css/font-awesome.min.css" />
            </Helmet>

            {isLoading ? <Loading /> : ""}

            <div className="container" style={{ padding: '0px 10px 50px 10px' }}>

                <div className="cardnew">

                    <div className="card-bodynew">



                        {/* <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: 22, fontWeight: 600 }}>
                            <div></div>

                        </div> */}
                        <div className='row'>
                            <div className='col-12'>
                                <div className='grayblkmy'>
                                    <div style={{ height: '25px', }}><span className='normaltxt' style={{ color: '#000' }}>Deposit Amount ({coinType})</span></div>
                                    <div className='textFigure'><span style={{ color: '#000' }}>{amount} {(coinType == "TRX" ? "TRX" : coinType == "DOGECOIN" ? "DOGECOIN" : 'USDT')}</span></div>
                                </div>
                                <img className='myicon' src={`img/${coinImage}`}  style={{ height: '50px' }}></img>
                            </div>
                        </div>
                        <div className="form-group" style={{marginTop:50}}>
                           
                            <div style={{ position: 'relative' }}>

                                <QRCode
                                    title={value}
                                    value={value}
                                    bgColor={back}
                                    fgColor={fore}
                                    size={size === '' ? 0 : size}
                                    style={{ marginLeft: '30%' }}
                                />

                                <input disabled="disabled" className="form-control valid" style={{ background: 'linear-gradient(315deg, #7FFFD4, #7FFFD4)', color: '#000', marginTop: 5, borderColor: '#0012d4 !important', paddingRight: 73 }} value={value} type="text" placeholder="Your Deposit Address" aria-invalid="false" />
                                <CopyToClipboard text={value} onCopy={CopyCallBack}>
                                    <a className='badge badge-dark' href="javascript:void(0);" style={{ position: 'absolute', textDecoration: 'none', top: '79%', fontSize: 20, right: 10, fontWeight: 500, color: '#fff' }}><i className='fa fa-copy'></i></a>
                                </CopyToClipboard>
                            </div>

                        </div>
                        {/* <div className="form-group">
                            <label htmlFor="ReferenceNo" style={{ marginBottom: 1 }}>2. Transfer the amount you want to recharge to us by UPI transfer.</label>
                            <label htmlFor="ReferenceNo">3. Please enter REF NO/Reference No/UTR (12-digit number) to complete the recharge.</label>
                            <input className="form-control" style={{ borderColor: '#0012d4 !important' }} id="ReferenceNo" onChange={handleUTRChange} ref={inputRefUTR} type="text" placeholder="ENTER REF NO:2070XXXXXXXX" />
                        </div> */}
                        <div style={{ textAlign: 'center', marginTop: 0 }}>
                            {/* <button className="btn btn-primary " type="button" style={{ width: '100%!important', padding: '15px 0px !important', whiteSpace: 'nowrap', fontSize: '18px !important' }}>Submit <i className="fa fa-inr" /><span className="RequestAmount">{amount}</span>
                            </button> */}
                            <p style={{ color: '#000', fontSize: 12, fontStyle: 'italic', marginTop: 10 }}>
                                {coinType == "TRX" ?
                                    "Please confirm only depositing TRX( TRON Network) to above deposit address USDT payments are not allowed, minimum deposit is 20 TRX less than 20 TRX payment will not be confimed.":
                                    coinType == "BTC"? "Please confirm depositing BTC to above deposit address other payments are not allowed, minimum deposit is 0.000080 BTC.":
                                    coinType == "USDT(TRC20)"? "Please confirm depositing USDT(TRC20 Chain TRON Network) to above deposit address TRX payments are not allowed, minimum deposit is 5 USDT less than 5 USDT payment will not be confimed.":
                                    coinType == "USDT(BEP20)"? "Please confirm depositing USDT(BEP20 Binance Network) to above deposit address TRC20 USDT payments are not allowed, minimum deposit is 5 USDT less than 5 USDT payment will not be confimed.":
                                    "Please confirm only depositing DOGECOIN( DOGECOIN Network) to above deposit address other payments are not allowed, minimum deposit is 20 Dogecoin less than 20 Dogecoin payment will not be confimed"
                                }

                            </p>
                            {/* <center style={{marginTop:'-70px'}}><label htmlFor="CompanyUPI">Our Payment Partners</label></center>
                            <img src='https://play-lh.googleusercontent.com/nJQ2XyuF3tWPspfAMy4kVEk5YLg2n-ZJQ_qExNagaPYiIfV_D3PW3_iAPBq8DY_uLw' className='partner'></img>&nbsp;
                            <img src='https://1000logos.net/wp-content/uploads/2022/03/Binance-logo.png' className='partner'></img>&nbsp;
                            <img src='https://trustwallet.com/assets/images/media/assets/vertical_blue.png' style={{height:75}} className='partner'></img> */}
                            
                            <img src='img/logo.png' className='partnerPayment'></img>
                        </div>

                    </div>
                </div>
            </div>



            <style
                dangerouslySetInnerHTML={{
                    __html: `


                    
                    .box {
                        --b: 5px;   /* thickness of the border */
                        --c: red;   /* color of the border */
                        --w: 20px;  /* width of border */
                        --r: 25px;  /* radius */
                        
                      
                        padding: var(--b); /* space for the border */
                        
                        position:relative;
                        /*Irrelevant code*/  
                        width:200px;
                        height:100px;
                        box-sizing:border-box;
                        margin:5px;
                        display:inline-flex;
                        font-size:30px;
                        justify-content:center;
                        align-items:center;
                        text-align:center;
                      }
                      .box::before {
                        content: "";
                        position: absolute;
                        inset: 0;
                        background: var(--c,red);
                        padding: var(--b);
                        border-radius: var(--r);
                        -webkit-mask:
                          linear-gradient(  0deg,#000 calc(2*var(--b)),#0000 0) 50% var(--b)/calc(100% - 2*var(--w)) 100% repeat-y,
                          linear-gradient(-90deg,#000 calc(2*var(--b)),#0000 0) var(--b) 50%/100% calc(100% - 2*var(--w)) repeat-x,
                          linear-gradient(#000 0 0) content-box,
                          linear-gradient(#000 0 0);
                        -webkit-mask-composite: destination-out;
                                mask-composite: exclude;
                      }
                    .qr-code {
                        border: 1px solid #000;
                        border-radius: 10px;
                        padding: 10px;
                      }
                    .partner{
                        margin-top:-49px!important;;
                        height:49px;
                    }
                    .partnerPayment{
                        margin-top: -22px!important;
                        height: 37px;
                        border-radius:10px;
                    
                    }
                    .myicon{
                        height: 50px;
                        position: absolute;
                        margin-left: 77%;
                        margin-top: -61px;
                      }
                      .withInput {
                        font-size: 16px!important;
                    }
                    .login-wrapper {
                        position: relative;
                        width: 100%;
                        min-height: 40vh;
                        z-index: 10;
                        overflow-y: auto;
                        padding-top: 0;
                        padding-bottom: 2rem;
                        overflow-x: hidden;
                        background-color: #f1f1f1;
                    }
                
                    .bg-blue {
                        background-color: #0081ff;
                        padding-left: 10px;
                        padding-bottom: 10px;
                    }
                
                    .bg-blue a {
                        color: #fff;
                    }
                
                    body {
                        background-color: #fff;
                    }
                
                    .page-content-wrapper {
                        position: relative;
                        z-index: 1;
                        margin-top: 30px !important;
                        margin-bottom: 62px;
                        padding: 30px 30px;
                    }
                
                    .minimal-tab .btn {
                        color: #fff;
                    }
                
                    .minimal-tab .btn.active {
                        color: #bfbebe !important;
                    }
                
                    .tab-content {
                        background: #fff;
                        padding: 10px 0px;
                    }
                
                    .form-label {
                        display: none;
                    }
                
                    .form-group {
                        line-height: 1;
                        margin-bottom: 5px;
                    }
                
                    select {
                        width: 100%;
                        padding: 10px;
                        border: 0px;
                        border-bottom: solid 1px #eee;
                        color: #9480b6;
                        background-color: #fff;
                    }
                
                    .form-control {
                        padding-top: 25px !important;
                        padding-bottom: 25px !important;
                        font-size: 16px !important;
                        padding-left: 15px !important;
                        height:0px;
                    }
                
                    label {
                        
                        font-size: 15px !important;
                    }
                    input[type=radio]{
                        transform:scale(1.6);
                      }
                      .radioLabel{
                        font-size:18px;
                        margin-left:5px;
                    }
                    .lock {
                        font-size: 18px !important;
                        color: #000 !important;
                        position: absolute !important;
                        top: 30px !important;
                        left: 12px !important;
                        z-index: 99 !important;
                    }
            
                    .list-wrap.list-wrap2 .price div {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        height: auto;
                        -webkit-box-align: end;
                        -ms-flex-align: end;
                        align-items: flex-end;
                        font-size: .6rem;
                        -webkit-transform: translateY(-0.22rem);
                        transform: translateY(-0.22rem);
                        margin-right: 11px !important;
                    }
            
                    .list-wrap.list-wrap2 .price {
                        background: #fff;
                        height: auto !important;
                        border-radius: 0.2rem;
                        padding-left: 0.2rem;
                        margin-top: 14px;
                    }
            
                        .list-wrap.list-wrap2 .price div input {
                            width: 90%;
                            font-size: 15px;
                            padding-left: 30px;
                            border: 1px solid #eee !important;
                            border-radius: 8px !important;
                            height: 40px;
                        }
            
                    input:focus {
                        outline: none !important;
                        border-color: #719ECE;
                    }
            
                    .list-wrap .btn {
                        line-height: 42px;
                    }
            
                    .topbg {
                        height: 82px !important;
                        overflow: hidden;
                        background: #ffffff;
                        background-size: 100% auto;
                        color: #FFFFFF;
                        padding-left: 0.26rem;
                    }
            
                    .btn-primary {
                        color: #fff;
                        background-color: #0BDA73 !important;
                        border-color: #0BDA73 !important;
            
                    .list-wrap .btn {
                        height: 0.88rem;
                        line-height: 44px;
                        background: #4D148C;
                        border-radius: 4px;
                        text-align: center;
                        color: #fff;
                        font-size: 14px;
                        font-weight: 400;
                        margin: 0.6rem 0 0.7rem 0;
                        cursor: pointer;
                        opacity: 1;
                        pointer-events: all;
                        width: 100%;
                        font-family: Microsoft YaHei !important;
                    }
            
                    .tips {
                        font-size: 10px;
                        color: #FF6600!important;
                        font-style: italic;
                    }
          `
                }}
            />
        </>
    )
}
