import React, { useState, useEffect, useRef } from 'react'
import axios from "axios";
import Loading from './Loading';
import { toast } from 'react-toastify';
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import BackButton from './BackButton';
export default function ProceedToWithdraw() {


    let navigate = useNavigate();
    const routeChange = (path) => {
        navigate('../' + path, { replace: true });
    };
   
    const [isLoading, setIsLoading] = useState(false);
   
    const [walletAmount, setwalletAmount] = useState(0);
    const [USDTwalletAmount, setUSDTwalletAmount] = useState(0);
    const [DogeWallet, setDogeWallet] = useState(0);
    
    useEffect(() => {
        GetWalletAmount();
    }, []);
    const GetWalletAmount = () => {
        setIsLoading(true);
        var param = new Object();
        param.MemberId = JSON.parse(localStorage.getItem("UserDetails").hexDecode()).ClientId;
        param.ActionMode = "GetWalletAmount";
        var obj = new Object();
        obj.procName = 'SenderRegistration';
        obj.Para = JSON.stringify(param);
        axios({
            method: 'POST',
            url: process.env.REACT_APP_WEBAPI_URL,
            data: obj
        }).then(function (res) {
            setIsLoading(false);
            res = res.data;
            setwalletAmount(res[0].WalletAmount);
            setUSDTwalletAmount(res[0].USDTWalletAmount);
            setDogeWallet(res[0].Doge);
        }).catch(function (error) {
            setIsLoading(false);
            console.log(error);
        });
    }
   
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Withdraw</title>
                <link rel="stylesheet" href="css/Recharge.css" />
                <link rel="stylesheet" href="css/font-awesome.min.css" />
            </Helmet>
            {isLoading ? <Loading /> : ""}
            <div class="recharge-wrap" style={{ backgroundColor: 'none' }}>
                <div className="navbar">
                    <BackButton path='home' />
                    <span style={{ marginRight: '25%', marginTop: '-15px' }}>Withdraw Modes</span>

                </div>
                <div hidden className='mydiv' style={{ display: 'block !important', paddingBottom: 11,marginLeft:'20%',marginTop:'11%' }}><div style={{ fontSize: '15px',color:'#fff' }}>Select Desired Withdrawal Options</div></div>
                <div className='row' style={{ margin: 0 }} onClick={() => routeChange('withdraw')}>
                    <div className='col-12'>
                        <div className='grayblkmy'>
                        <img src='img/tronlogo.png' className='wimg'></img>
                            <div style={{ height: '25px', }}><span className='normaltxt' style={{ color: '#fff' }}>TRX</span></div>
                            <div className='textFigure' style={{color:'#fff',fontSize:16}}><span style={{ color: '#fff' }}>{walletAmount} TRX</span></div>
                           
                        </div>
                        <span className='myicon'>Shoot Now <i className="fa fa-chevron-right" style={{color:'#fff'}}></i></span>
                        {/* <i className="fa fa-chevron-right myicon" style={{color:'#fff'}}></i> */}
                        {/* <img className='myicon' src='img/tron.png' style={{ height: '50px' }}></img> */}
                    </div>
                </div>
                <div className='row' style={{ margin: 0 }} onClick={() => routeChange('withdrawusdt')}>
                    <div className='col-12'>
                    
                        <div className='grayblkmy'>
                        <img src='img/tether_trc20.png' className='wimg'></img>
                            <div style={{ height: '25px', }}><span className='normaltxt' style={{ color: '#fff' }}>USDT</span></div>
                            <div className='textFigure' style={{color:'#fff',fontSize:16}}><span style={{ color: '#fff' }}>{USDTwalletAmount} USDT</span></div>
                            
                        </div>
                        <span className='myicon'>Shoot Now <i className="fa fa-chevron-right" style={{color:'#fff'}}></i></span>
                    </div>
                </div>
                <div className='row' style={{ margin: 0 }} hidden>
                    <div className='col-12'>
                        <div className='grayblkmy'>
                            <div style={{ height: '25px', }}><span className='normaltxt' style={{ color: '#fff' }}>DOGE</span></div>
                            <div className='textFigure' style={{color:'#fff',fontSize:16}}><span style={{ color: '#fff' }}>{DogeWallet} DOGE</span></div>
                            
                        </div>
                        <span className='myicon'>Coming Soon</span>
                    </div>
                </div>
                <center><span className='badge badge-dark' style={{fontSize:18,backgroundColor:'#000'}}>Rate : 1 TRX=0.0714 USDT</span></center>
                <center><img src='img/logo.png' className='partnerPayment'></img></center>
            </div>
            <style
                dangerouslySetInnerHTML={{
                    __html: `
                    .partnerPayment{
                        margin-top: -22px!important;
                        height: 39px;
                    
                    }
                    .wimg{
                        height: 54px;
                        position: absolute;
                        margin-left: 25%;
                    }
                    .recharge-wrap{
                        background:none;
                    }
                    .mydiv{
                        margin-left: 20%;
                        margin-top: 11%;
                        background-color: #000;
                        /* padding: 5px; */
                        width: 68%;
                        padding: 5px;
                        border-radius: 6px;

                    }
                    /* Chrome, Safari, Edge, Opera */
                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                    }
                    ::placeholder {
                        color: #000 !important;
                        font-family: Microsoft YaHei !important;
                        font-style:italic;
                        font-size:14px;
                    }
                    .withInput {
                        width: 100%;
                        font-size: 18px !important;
                        padding: -3px !important;
                        /* height: 40px; */
                        /* background-color: transparent !important; */
                        border: 0px;
                        border: solid 1px #dc3545;
                        /* margin-bottom: 10px; */
                        box-shadow: none;
                        border-radius: 8px;
                        background-color:antiquewhite;
                    }
                    .myicon{
                       
                        position: absolute;
                        margin-left: 77%;
                        margin-top: -61px;
                        color: rgb(255, 255, 255);
                        font-size: 18px;
                        margin-top: -49px;
                        margin-left: 62%;
                        background-color: #000;
                        padding: 3px;
                        border-radius: 12px;
                      }
                    /* Firefox */
                    input[type=number] {
                    -moz-appearance: textfield;
                    }
                    // body {
                    //     background:black url(http://fc01.deviantart.net/fs71/f/2012/160/b/9/stars_by_paulinemoss-d52un4e.jpg);
                    //     animation: stars 250s linear alternate;
                    //   }
                    //   @keyframes stars {
                    //     0% { background-position:0 0 }
                    //     100% { background-position:0 100% }
                    //   }
                    .lock {
                        font-size: 18px !important;
                        color: #000 !important;
                        position: absolute !important;
                        top: 30px !important;
                        left: 12px !important;
                        z-index: 99 !important;
                    }
           
                    .list-wrap.list-wrap2 .price div {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        height: auto;
                        -webkit-box-align: end;
                        -ms-flex-align: end;
                        align-items: flex-end;
                        font-size: .6rem;
                        -webkit-transform: translateY(-0.22rem);
                        transform: translateY(-0.22rem);
                        margin-right: 11px !important;
                    }
            
                    .list-wrap.list-wrap2 .price {
                        background: #fff;
                        height: auto !important;
                        border-radius: 0.2rem;
                        padding-left: 0.2rem;
                        margin-top: 14px;
                    }
            
                        .list-wrap.list-wrap2 .price div input {
                            width: 90%;
                            font-size: 15px;
                            padding-left: 30px;
                            border: 1px solid #eee !important;
                            border-radius: 8px !important;
                            height: 40px;
                        }
            
                    input:focus {
                        outline: none !important;
                        border-color: #719ECE;
                    }
            
                    .list-wrap .btn {
                        line-height: 42px;
                    }
            
                    .topbg {
                        height: 82px !important;
                        overflow: hidden;
                        background: #ffffff;
                        background-size: 100% auto;
                        color: #FFFFFF;
                        padding-left: 0.26rem;
                    }
            
            
            
                    .list-wrap .btn {
                        height: 0.88rem;
                        line-height: 44px;
                        background: #4D148C;
                        border-radius: 4px;
                        text-align: center;
                        color: #fff;
                        font-size: 14px;
                        font-weight: 400;
                        margin: 0.6rem 0 0.7rem 0;
                        cursor: pointer;
                        opacity: 1;
                        pointer-events: all;
                        width: 100%;
                        font-family: Microsoft YaHei !important;
                    }
            
                    .tips {
                        font-size: 10px;
    color: #fff !important;
    font-style: normal;
    font-weight:normal !important;
    margin-bottom:10px;
                    }
                    .btn-info{
                        border:none;
                        border-radius:5px;
                    }
          `
                }}
            />
        </>
    )
}
