import React from 'react'
import Home from "./components/Home"
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from "react-router-dom";
import Footer from './components/Footer';
import Login from './components/Login';
import Register from "./components/Register";
import RecoverPassword from "./components/RecoverPassword";
import Invite from "./components/Invite";
import Team from "./components/Team";
import Profile from "./components/Profile";
import Reinvest from "./components/Reinvest";
import RechargeWallet from "./components/RechargeWallet";
import UserProfile from "./components/UserProfile";
import Withdraw from "./components/Withdraw";
import Company from "./components/Company";
import BankCard from "./components/BankCard";
import PayNow from "./components/PayNow";
import ChangePassword from "./components/ChangePassword";
import Order from "./components/Order";
import Incomestatement from "./components/Incomestatement";
import Deposit from './components/Deposit';
import DepositUSDT from './components/DepositUSDT';
import Share from './components/Share';
import AboutCompany from './components/AboutCompany';
import Reward from './components/Reward';
import ProceedToWithdraw from './components/ProceedToWithdraw';
import WithdrawUSDT from './components/WithdrawUSDT';
import SignIn from './components/SignIn';
import AuthIn from './components/Auth';
import SignUp from './components/CreateAccount';
import Plans from './components/Plans';
// import TabNav from './components/TabNav';
import TabNav from './components/BottomNav';
import TeamCard from './components/TeamCard';
function App() {
  let location = useLocation();
  console.log(location.pathname)
  return (
    <>
      <Routes>
        <Route exact path='/' element={!localStorage.getItem("UserDetails") ? <Navigate to='/auth' /> : <Navigate to='/home' />} />
        <Route exact path='/home' element={!localStorage.getItem("UserDetails") ? <Navigate to='/auth' /> : <Home />} />
        <Route exact path="/auth" element={<AuthIn />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/signin" element={<SignIn />} />
        <Route exact path="/signup" element={<SignUp />} />
        <Route exact path="/createaccount" element={<Register />} />
        <Route exact path="/recoverpassword" element={<RecoverPassword />} />
        <Route exact path="/teambylevel/:levelno" element={<TeamCard />} />
        <Route exact path="/invite" element={<Invite />} />
        <Route exact path="/team" element={<Team />} />
        <Route exact path="/profile" element={<Profile />} />
        <Route exact path="/UserProfile" element={<UserProfile />} />
        <Route exact path="/plans" element={<Plans />} />
        <Route exact path="/recharge" element={<RechargeWallet />} />
        <Route exact path="/depositbyusdt" element={<DepositUSDT />} />
        <Route exact path="/reward" element={<Reward />} />
        <Route exact path="/transfertobasic" element={<Reinvest />} />
        <Route exact path="/withdraw" element={<Withdraw />} />
        <Route exact path="/withdrawusdt" element={<WithdrawUSDT />} />
        <Route exact path="/withdrawoption" element={<ProceedToWithdraw />} />
        <Route exact path="/company" element={<Company />} />
        <Route exact path="/pay" element={<PayNow />} />
        <Route exact path="/changePassword" element={<ChangePassword />} />
        <Route exact path="/order" element={<Order />} />
        <Route exact path="/income" element={<Incomestatement />} />
        <Route exact path="/AboutCompany" element={<AboutCompany />} />
      </Routes>
      {(location.pathname === '/' || location.pathname === '/auth' || location.pathname === '/login' || location.pathname === '/signin' || location.pathname === '/signup' || location.pathname.toLocaleLowerCase() === '/createaccount' || location.pathname === '/recoverpassword') ? null : <Footer />}

    </>
  );
}

export default App;
