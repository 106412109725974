import React from 'react'
import { useNavigate } from "react-router-dom";
export default function Footer() {
  let navigate = useNavigate();
  const routeChange = (path) => {
    navigate('../' + path, { replace: true });
  };
  return (
    <>
      <div className="tab-bar">
        <div className="active" onClick={() => routeChange('home')}>
          <i />
          <p>Home</p>
        </div>
        <div onClick={() => routeChange('plans')}>
          <i style={{ height: 24, width: 30 }} />
          <p>Plans</p>
        </div>
        <div onClick={() => routeChange('team')}>
          <i style={{ height: 39, width: 37,marginTop:'-3px' }}/>
          <p style={{marginTop:-5}}>Team</p>
        </div>
        <div onClick={() => routeChange('invite')}>
          <i />
          <p>Invite</p>
        </div>
        <div onClick={() => routeChange('UserProfile')}>
          <i />
          <p>Profile</p>
        </div>
      </div>
     
    </>
  )
}
