import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, Link } from "react-router-dom";
import PackageCard from './PackageCard';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import axios from "axios";
import Loading from './Loading';
import { Helmet } from "react-helmet";
import Tabs from './Tabs';
import { toast } from 'react-toastify';
import HomePopup from './HomePopup';
import Lanaguage from './Lanaguage';

export default function Home() {
  let navigate = useNavigate();
  const routeChange = (path) => {
    navigate('../' + path, { replace: true });
  };
  const [value, setValue] = useState("");
  const [userName, setUserName] = useState("");
  useEffect(() => {
    let URL = "";
    let uName = "";
    let xCode = JSON.parse(localStorage.getItem("UserDetails").hexDecode());
    uName = xCode.UserName;
    URL = xCode.ReferralURL;
    setValue(URL + uName);
    setUserName(uName);
  }, []);
  const CopyCallBack = (t, r) => {
    if (r == true) {
      toast.success("Invite Code Copied!!");
    }
  }
  const [isShowPopup, setPopup] = useState(false);
  const [isShowTokenPopup, setTokenPopup] = useState(false);
  //Handling Loadin"p
  const [isLoading, setIsLoading] = useState(false);
  const [walletAmount, setwalletAmount] = useState(0);
  const [tokenValue, settokenValue] = useState(0);
  const [tokenBalance, settokenBalance] = useState(0);
  const [rechargeAmount, setRechargeAmount] = useState(0);
  useEffect(() => {
    //BindPackage();
    GetWalletAmount();
  }, []);
  const GetWalletAmount = () => {
    setIsLoading(true);
    var param = new Object();
    param.MemberId = JSON.parse(localStorage.getItem("UserDetails").hexDecode()).ClientId;
    param.ActionMode = "GetWalletAmount";
    var obj = new Object();
    obj.procName = 'SenderRegistration';
    obj.Para = JSON.stringify(param);
    axios({
      method: 'POST',
      url: process.env.REACT_APP_WEBAPI_URL,
      data: obj
    }).then(function (res) {
      setIsLoading(false);
      res = res.data;
      setwalletAmount(res[0].WalletAmount);
      setRechargeAmount(res[0].JoiningAmount);
      settokenValue(res[0].TokenValue);
      settokenBalance(res[0].tokenBalance);
    }).catch(function (error) {
      setIsLoading(false);
      console.log(error);
    });
  }

  return (
    <>
      <Helmet>
        <script src="https://quantifycrypto.com/widgets/grid/js/qc-grid-widget.js"></script>
        <script defer src="https://www.livecoinwatch.com/static/lcw-widget.js"></script>
      </Helmet>
      {isLoading ? <Loading /> : ""}
      <div className="lease wrap">
        {isShowPopup ?
          <div id="mask">

            <div className="mask-body" onclick="stopPropagation()" style={{ height: 160, borderRadius: 10, background: '#fff' }}>
              <img src="img/popup1.png" style={{ width: '100%' }}></img>
              <i className='fa fa-close closePopup' onClick={() => setPopup(false)}></i>

              <div className='btnPoup'>

                <button><a href='https://t.me/tronminergroup' style={{ color: '#fff' }}>Channel</a></button>
                <button><a href='https://t.me/tronmineradmin' style={{ color: '#fff' }}>Support</a></button>
                {/* <button><a href='https://tronpower.space/TPOWER/Index.aspx' style={{ color: '#fff' }}>Website</a></button> */}
              </div>
            </div>

          </div>
          : null
        }
        {isShowTokenPopup ?
          <div id="mask">

            <div className="mask-body" onclick="stopPropagation()" style={{ height: 160, borderRadius: 10, background: 'none', boxShadow: 'none' }}>
              <img src="img/tokenlaunch.png" style={{ width: '100%' }}></img>

              <i className='fa fa-close closePopup' style={{ color: '#000' }} onClick={() => setTokenPopup(false)}></i>

            </div>

          </div>
          : null
        }
        <div className="banner">
          <div className="row" style={{ height: 57, backgroundColor: '#fff', borderRadius: '13px' }}>
            <div className="col-4" style={{ padding: '0px', marginLeft: '7px', marginTop: '-11px' }}>
              <img src="img/logo.png" style={{ height: '32px', verticalAlign: 'super' }} />
            </div>
            <div className="col-3" style={{ padding: '0px' }}>
              <Lanaguage></Lanaguage>
            </div>
            <div className="col-4" style={{ padding: '0px', marginTop: '1px', marginLeft: '6%' }}>
              <a href='https://t.me/maximtrademaster'>
                <img src="img/costumer-service.png" className='blink1' style={{ height: '45px', float: 'right', borderRadius: 23 }} />

              </a>
            </div>

          </div>



        </div>



        <div className='row wallet'>
          <div className='col-12'>
            <div className='graybg' style={{ height: 77, borderRadius: 2 }}>
              <div style={{ height: '25px', color: '#000' }}><span className='normaltxt'>Balance</span></div>
              <div className='textFigure' style={{ color: '#000' }}><span>{walletAmount} TRX</span></div>
              {/* <div className='textFigure' style={{ color: '#fff', position: 'absolute', marginTop: '-13%', marginLeft: '39%', fontSize: 16 }}><span>Mining Hash Rate 10GH/s</span></div> */}
            </div>
          </div>
        </div>
        <div className='row wallet' style={{marginTop:5}}>
          <div className='col-12'>
            <div className='grayblk1'>
              <div style={{ height: '25px', color: '#000' }}><span className='normaltxt'>Deposited Amount</span></div>
              <div className='textFigure' style={{ color: '#000' }}><span>{tokenBalance} TRX</span></div>
            </div>
            <img className='myicon' src='img/tronlogo.png' style={{ height: '50px' }}></img>
          </div>
        </div>

        <div  className='row newsRow' style={{ borderRadius: '30px', padding: '8px',height:55, margin: 0, marginTop: 5, background: '#181D1F', boxShadow: '-1px 1px 12px 0px #ffc10740' }}>

          {/* <div className='col-2' style={{ marginLeft: '-15px' }}><img src="img/mic.png" style={{ height: '28px' }} /></div>
          <div className='col-10' style={{ margin: 0 }}><marquee scrollamount="3" style={{ fontSize: 14, color: '#fff' }}>Welcome to <span style={{color:'green'}}>ZenGo</span>, New Era of TRX Earning , Just Stake Your TRX to Grow more we utilize your funding in mining & forex Trading to maintain money liquidity to avail smooth and instant withdraw</marquee></div> */}
          <div class="livecoinwatch-widget-5" lcw-base="USD" lcw-color-tx="#ffffff" lcw-marquee-1="coins" lcw-marquee-2="none" lcw-marquee-items="10" ></div>

        </div>
        <div className="row optMenu menuCard" style={{ height: 96 }}>
          <div className="col-3" style={{ padding: '0px', marginTop: '-18px' }} onClick={() => routeChange('recharge')}>
            <center> <img src="img/savemoney.png" style={{ width: '57%' }} alt />
              <div style={{ fontSize: '0.26em', color: '#000' }}>Deposit</div>
            </center>

          </div>
          <div className="col-3" style={{ padding: '0px', marginTop: '-18px' }} onClick={() => routeChange('withdraw')}>
            <center> <img src="img/cashw.png" style={{ width: '57%' }} alt />
              <div style={{ fontSize: '0.26em', color: '#000' }}>Withdrawal</div>
            </center>

          </div>
          <div className="col-3" style={{ padding: '0px', marginTop: '-18px' }} onClick={() => routeChange('invite')}>
            <center> <img src="img/exchange.png" style={{ width: '57%' }} alt />
              <div style={{ fontSize: '0.26em', color: '#000' }}>Refer</div>
            </center>

          </div>
          <div className="col-3" style={{ padding: '0px', marginTop: '-18px' }} onClick={() => window.open('https://t.me/+sbQLH9yrye00OWE9')}>
            <center> <img src="img/gram.png" style={{ width: '57%' }} alt />
              <div style={{ fontSize: '0.26em', color: '#000' }}>Official</div>
            </center>

          </div>
          {/* <div className="col-3" style={{ padding: '0px', marginRight: '8%', marginTop: '-18px' }} onClick={() => routeChange('invite')}>

            <center> <img src="img/shareme.png" style={{ width: '57%' }} alt />
              <div style={{ fontSize: '0.26em', color: '#000' }}>Share</div>
            </center>

          </div>
          <div className="col-3" style={{ padding: '0px', marginRight: '8%', marginTop: '-18px' }}>
            <a href='https://t.me/tronminergroup' style={{ color: '#000', textDecoration: 'none' }}>
              <center> <img src="img/tele.png" style={{ width: '57%' }} alt />
                <div style={{ fontSize: '0.26em' }}>Official Group</div>
              </center>
            </a>
          </div>
          <div className="col-3" style={{ padding: '0px', marginRight: '8%', marginTop: '-18px' }} onClick={() => routeChange('AboutCompany')}>
            <center> <img src="img/myinfo.png" style={{ width: '57%' }} alt />
              <div style={{ fontSize: '0.26em' }}>Information</div>
            </center>

          </div> */}
        </div>
        <div  className='row newsRow' style={{ borderRadius: '30px', padding: '7px', margin: 0, marginTop: 5, background: '#181D1F', boxShadow: '-1px 1px 12px 0px #ffc10740' }}>


          <div className='col-12' style={{ margin: 0 }}>

            <span style={{ color: '#fff', fontSize: 13 }}>https://zengo.live//signup?ref={userName}</span>
            <CopyToClipboard text={value} onCopy={CopyCallBack}>
              <button style={{ float: 'right', border: 'none',background:'#181D1F' }}><i className='fa fa-copy' style={{ fontSize: 20, color: '#fff' }}></i></button>
            </CopyToClipboard>

          </div>


        </div>
       

        <div className='row cryptoblock' hidden>
          <div className="col-12" style={{ fontSize: '16px', marginTop: '10px', color: '#fff' }} ><center><strong>Market 24 Hr Change</strong></center></div>
          <div className='col-12'>
            <qc-grid-widget theme="dark" width="329px" ranking-type="top_20" currency-code="USD"></qc-grid-widget>
          </div>
        </div>
        <div className='row cryptoblock'>
          <div className="col-12" style={{ fontSize: '16px', marginTop: '10px', color: '#000' }} ><center><strong>About ZenGo</strong></center></div>
          <div className='col-12'>
            <p style={{ fontSize: '11px', marginBottom: '10px', color: '#000' }}>
            ZenGo was launched in 2023 and is one of the leading TRX cloud mining service providers. The purpose of this company is to maximize the interests of each user, so they can leverage large wealth with relatively low capital. “Invest once, enjoy forever,” that’s why all options are recognized and followed by ZenGo. Through future data analysis, users should understand that ZenGo pursues long-term strategic cooperative relationships with users, which is the reason they’ve grown to over 1 million members at this time. Launched in 2018, ZenGo is a cloud mining service built on the Tron network. It provides users with a safe, convenient, and efficient TRX cloud mining experience. Users can visit the official ZenGo website and register for an account with ease. The company enables users to mine the TRX cryptocurrency with a low-cost cloud service. Whether it’s quantitative trading or DeFi technology, ZenGo makes it easy to participate in the blockchain revolution with its cloud mining services. To start cloud mining with ZenGo, users can visit their website, register with an email, deposit TRX and the service will automatically start mining the TRX cryptocurrency for you.</p>
            {/* <p style={{ fontSize: '13px', marginBottom: '10px', color: '#000' }}>The company registration information is as follows:</p>
            <p style={{ fontSize: '11px', marginBottom: '10px', color: '#000' }}>Company Name: ZenGo Limited</p>
            <p style={{ fontSize: '11px', marginBottom: '10px', color: '#000' }}>Company Registration Number: 10370911</p>
            <p style={{ fontSize: '11px', marginBottom: '10px', color: '#000' }}>Established: 20 February 2018</p>
            <p style={{ fontSize: '11px', marginBottom: '10px', color: '#000' }}>Registered Address: 24 Hanson Street, London, United Kingdom, W1W 6UH</p>
            <p style={{ fontSize: '11px', marginBottom: '10px', color: '#000' }}>Registered capital: USD 50 million</p>
            <center style={{ marginTop: '-12%' }} hidden>
              <a href='img/certificate.pdf' className='companybtn' type='button' download>Certificate</a>
            </center> */}
          </div>

        </div>
      </div>
      <style
        dangerouslySetInnerHTML={{
          __html: `
          #quantify-link{
            display:none!important;
          }
          .ag-header-cell-text{
            font-size:12px!important;
          }
          .qc-col{
            display:none!important;
          }
          .companybtn {
            display: inline-block;
            padding: 4px 10px;
            text-align: center;
            border-radius: 15px;
            line-height: 17px;
            color: var(--btn-text);
            font-size: 12px;
            font-weight: 700;
            background: linear-gradient(315deg, #f4a908, #71A834)!important;
            height:30px;
            width:34%;
            color:#000;
            text-decoratrion:none;
        }
          .cryptoblock{
            box-shadow: -1px 1px 12px 0px #6c757d5c;
            margin: 0;
            margin-top: 7px;
            border-radius: 15px;
            padding: 7px;
          }
          .cryptoButton{
            width: 39.46667vw;
            height: 9.33333vw;
            border-radius: 6.66667vw;
            font-size: 4.26667vw;
            font-weight: 700;
            color: #fff;
            background: linear-gradient(1turn,#0b45c9,#1b78d2);
            box-shadow: 1px 1px 0.8vw #c8c9cc;
            border: 1px solid #1b78d2;
            margin: 2.66667vw auto 0;
                  }
          .menuCard{
            margin: 0;
            margin-top: 7px;
            border-radius: 18px;
            padding:5px;
          }
          .wallet{
            //background-image: url(https://www.shutterstock.com/image-illustration/digital-cryptocurrency-block-chain-market-600nw-1939827760.jpg);
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            border-radius: 10px;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
            margin:0;
            background:aquamarine;
          }
          .swapNow{
            margin-left: 4%;
    background: #000;
    border-radius: 0px;
          }
          .nav-item{
            margin-left:15%;
          }
          .livecoinwatch-widget-3{
            width:344px!important;
          }
          .livecoinwatch-widget-5{
            margin-top:-5px!important;
          }
          .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
            font-size:20px!important;
            color:#000;
            border-bottom:red!important;
          }
          .myclasses{
            list-style: none;
            line-height: 21.5px;
            margin-right: 14%;
            font-size: 15px;
            margin-top: 4%;
            font-weight:600;
            color: #212529;
          }
          .cg-bold{
            display:none;
          }
          .myicon {
            height: 106px;
            position: absolute;
            margin-left: 62%;
            margin-top: -143px;
          }
          .h1eRzx{
            display:none;
          }
          /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
.closePopup{
  position: absolute;
    margin-top: -6%;
    /* margin-right: -28%; */
    color: #fff;
    font-size: 27px;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.blink {
  animation: blink 2s steps(5, start) infinite;
  -webkit-animation: blink 2s steps(5, start) infinite;
}
@keyframes blink {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink {
  to {
    visibility: hidden;
  }
}
          .l_title h1 {
              font-size: 17px;
              font-weight: 500;
          }

          .lease .item>li {
              background: #343a40;
              border-radius: 8px;
              position: relative;
              margin-bottom: 0.2rem;
              list-style: none;
          }

          .mask-tips>div .text p {
              text-align: center;
              font-size: .24rem;
              line-height: .39rem;
              color: #ffffff;
              font-weight: 400;
              margin: 1px;
          }

          
          .lease {
              /* background: #F5F6F7; */
              min-height: 100vh;
              padding: 0 0.3rem 1.8rem 0.3rem;
              background-color: #fff !important;
          }

          .mask {
              position: fixed;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              z-index: 40;
              background: rgba(0, 0, 0, 0.4);
          }

          .mask-body h3 div {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-pack: justify;
              -ms-flex-pack: justify;
              justify-content: space-between;
              padding: 0 0.3rem;
              padding-top: 1rem;
          }

          .layui-layer-wrap p {
              font-size: 14px;
          }

          .layui-layer-title {
              background: #e51837;
              ;
              color: #fff;
              border: none;
              text-align: center;
              padding: 0px;
              border-radius: 10px 10px 0px 0px;
          }

          .layui-layer-title {
              padding: 0 80px 0 20px;
              height: 50px;
              line-height: 50px;
              border-bottom: 1px solid #F0F0F0;
              font-size: 14px;
              color: #fff;
              overflow: hidden;
              border-radius: 2px 2px 0 0;
          }

          .announcement-title[data-v-eebac136] {
              font-size: 20px;
              font-weight: 600;
              color: #fff;
              margin-left: 3px;
              margin-bottom: 0.32rem;
              height: 0.64rem;
              line-height: .64rem;
              padding-left: 5px;
              border-bottom: 3px solid #FF6600;
          }

          .lease .notice>ul li {
              width: 33%;
              text-align: center;
              list-style: none;
              line-height: 13px;
          }

          .tesladiv {
              box-shadow: none !important;
              border-radius: 8px;
              margin-right: 5px;
              margin-bottom: 10px;
              display: flex !important;
              background: #fff !important;
              /* flex-direction: row-reverse; */
              justify-content: space-around;
          }

          div.myitem {
              display: flex;
              flex-wrap: wrap;
              height: auto;
              justify-items: center;
              margin: 0px;
              background: #fff;
              box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
          }

          .myl_left img {
              width: 100%;
              height: 4rem;
          }

          .myl_left {
              text-align: end;
          }

          .myl_left {
              width: 100%;
          }
          .lease .banner {
           
          }
          .myl_right {
              padding: 10px 5px;
              width: 100%;
              background: #fff;
              margin-top: -0px;
          }

          .myl_title {
              margin-bottom: 10px;
          }

          .myl_meta {
              /* display: flex;
              width: 100%;
              flex-wrap: wrap; */
          }

          .mymetal {
              width: 50%;
              margin: 0 !important;
              padding: 0 !important;
              -webkit-tap-highlight-color: transparent;
              float: left;
              height: 20px;
          }

          .myl_desc {
              color: #000;
              font-size: 12px;
              vertical-align: top;
          }

          .myday {
              width: 30%;
              font-size: 12px;
              color: #000;
              vertical-align: top;
          }
          .btn-info.focus, .btn-info:focus{
            box-shadow: none !important;
        }

          .myamount {
              width: 30%;
              font-size: 11px;
              color: #000;
              vertical-align: top;
              font-weight:600
          }

          .mystatus_icon {
              height: 35px !important;
              width: 35px !important;
              position: absolute;
              right: 17px;
          }
          `
        }}
      />
    </>
  )
}
