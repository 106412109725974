import React, { useState, useRef, useEffect } from 'react'
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import Loading from './Loading';
import { toast } from 'react-toastify';

export default function Register() {
    let navigate = useNavigate();
    let params = (new URL(document.location)).searchParams;

    const routeChange = (path) => {
        navigate('../' + path, { replace: true });
    };
    const inputEls = useRef([]);
    //Handling Loading 
    const [isLoading, setIsLoading] = useState(false);
    //State Management
    const [allValues, setAllValues] = useState({
        Name: '',
        MobileNo: '',
        Password: '',
        ConfirmPassword: '',
        TransactionPassword: '123456',
        SponsorId: params.get("ref")
    });
    const changeHandler = e => {
        setAllValues({ ...allValues, [e.target.name]: e.target.value })
    }

    const doRegistration = () => {
        if (allValues.Name == "") {
            toast.error('Enter Nick Name');
            inputEls.current[4].focus();
            return;
        }
        if (allValues.MobileNo == "") {
            toast.error('Enter Mobile No.');
            inputEls.current[1].focus();
            return;
        }
        if (allValues.MobileNo.toString().length < 10 || allValues.MobileNo.toString().length > 15) {
            toast.error('Enter Valid Mobile No.');
            inputEls.current[1].focus();
            return;
        }
        if (allValues.Password == "") {
            toast.error('Enter Password.');
            inputEls.current[2].focus();
            return;
        }
        if (allValues.ConfirmPassword == "") {
            toast.error('Enter Confirm Password.');
            inputEls.current[3].focus();
            return;
        }
        // if (allValues.ConfirmPassword != allValues.Password) {
        //     toast.error('Password and Confirm Password do not matched.');
        //     inputEls.current[3].focus();
        //     return;
        // }
        // if (allValues.TransactionPassword == "") {
        //     toast.error('Enter Withdrawal Password.');
        //     inputEls.current[4].focus();
        //     return;
        // }
        if (allValues.SponsorId == "") {
            toast.error('Enter Invite Code.');
            inputEls.current[5].focus();
            return;
        }
        setIsLoading(true);
        var param = new Object();
        param.Name = allValues.Name;
        param.SponsorUserName = allValues.SponsorId;
        param.Password = allValues.Password;
        param.ConfirmPassword = allValues.ConfirmPassword;
        param.TransactionPassword = allValues.TransactionPassword;
        param.ConfirmTransactionPassword = allValues.TransactionPassword;
        param.MobileNo = allValues.MobileNo;
        var obj = new Object();
        obj.procName = 'TronZRegistration';
        obj.Para = JSON.stringify(param);
        axios({
            method: 'POST',
            url: process.env.REACT_APP_WEBAPI_URL,
            data: obj
        }).then(function (res) {
            setIsLoading(false);
            res = res.data;
            if (res[0].StatusCode == "1") {
                toast.success('Great,You are In, Happy Staking...');
                var UserDetails = JSON.stringify(res[0]);
                UserDetails = UserDetails.hexEncode();
                localStorage.setItem("UserDetails", UserDetails);
                setTimeout(function () {
                    routeChange('home');
                }, 1000)
            }
            else {
                toast.error(res[0].Msg);
            }
        }).catch(function (error) {
            setIsLoading(false);
        });
    }

    const [passwordType, setPasswordType] = useState("password");
    const [ConfirmpasswordType, setConfirmPasswordType] = useState("password");
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }
    const toggleConfirmPassword = () => {
        if (ConfirmpasswordType === "password") {
            setConfirmPasswordType("text")
            return;
        }
        setConfirmPasswordType("password")
    }

    return (
        <>
            {isLoading ? <Loading /> : ""}
            <div className='signinBg'>
                <div style={{ backgroundColor: 'transparent' }} onClick={() => routeChange('login')}>
                    {/* <span ><i className='fa fa-arrow-left' style={{ marginTop: 28, color: '#fff'}}></i></span> */}
                    {/* <span className='signuptext'>Sign Up</span> */}
                </div>
                <div className="signin-wrap wrap">
                 
                    <img onClick={() => window.location.href = 'https://t.me/cryptoyardadm'} src="https://cdn-icons-png.flaticon.com/512/4298/4298326.png" style={{ height: 35, marginTop: '-94px', float: 'right' }}></img>
                    <div className="logo">
                        <img src="img/logo.png?5" alt />
                    </div>
                    <ul id="app" style={{ padding: 6, marginTop: '0.266667rem', marginBottom: '0px', width: '100%', borderRadius: '0.266667rem' }}>
                        <li className="item icon2" style={{ lineHeight: '0.88rem', border: '1px solid #ffc107' }}>
                            <input id="Name" type="text" name="Name" placeholder="Enter User ID" ref={(el) => (inputEls.current[4] = el)} onChange={changeHandler} />
                        </li>
                        <li className="item icon2" style={{ lineHeight: '0.88rem', border: '1px solid #ffc107' }} >
                            <input id="txtMobileNo" ref={(el) => (inputEls.current[1] = el)} onChange={changeHandler} type="number" name="MobileNo" placeholder="Enter Mobile No." />

                        </li>

                        <li className="item icon2" style={{ lineHeight: '0.88rem', border: '1px solid #ffc107' }}>
                            <input id="txtPassword" ref={(el) => (inputEls.current[2] = el)} onChange={changeHandler} name="Password" type={passwordType} placeholder="Input Password" />
                            {passwordType === "password" ? <i className="myeye fa fa-eye-slash" onClick={togglePassword}></i> : <i className="myeye fa fa-eye" onClick={togglePassword}></i>}
                        </li>
                        <li className="item icon2" style={{ lineHeight: '0.88rem', border: '1px solid #ffc107' }}>
                            <input id="txtConfirmPassword" ref={(el) => (inputEls.current[3] = el)} onChange={changeHandler} name="ConfirmPassword" type={ConfirmpasswordType} placeholder="Confirm Password" />
                            {ConfirmpasswordType === "password" ? <i className="myeye fa fa-eye-slash" onClick={toggleConfirmPassword}></i> : <i className="myeye fa fa-eye" onClick={toggleConfirmPassword}></i>}
                        </li>
                        {/* <li className="item icon2 mar">
                        <input id="txtTransactionPassword" ref={(el) => (inputEls.current[4] = el)} onChange={changeHandler} name="TransactionPassword" type="password" placeholder="Enter Withdrawal password" />
                        <i className="fa fa-lock" />
                    </li> */}
                        <li className="item icon2" style={{ lineHeight: '0.88rem', border: '1px solid #ffc107' }}>
                            <input value={allValues.SponsorId ? allValues.SponsorId : ""} id="txtSponsorId" name="SponsorId" ref={(el) => (inputEls.current[5] = el)} onChange={changeHandler} type="text" placeholder="Enter invite code" />

                        </li>




                    </ul>
                    <button type="button" id="btnSubmit" onClick={doRegistration} style={{width:'89%',marginLeft:'5%', color: '#fff', borderColor: ' ' }} className="btn btn-info" >Register</button>
                    <div style={{ fontSize: 16, color: '#000', boxShadow: 'none!important', textAlign: 'center', marginTop: '5px' }} onClick={() => routeChange('login')}>Already have an account? Signin</div>
                </div>
            </div>
            <style
                dangerouslySetInnerHTML={{
                    __html: `
                    // body {
                    //     background:black url(http://fc01.deviantart.net/fs71/f/2012/160/b/9/stars_by_paulinemoss-d52un4e.jpg);
                    //     animation: stars 250s linear alternate;
                    //   }
                    //   @keyframes stars {
                    //     0% { background-position:0 0 }
                    //     100% { background-position:0 100% }
                    //   }
                    ::placeholder {
                        color: #000 !important;
                        font-family: Microsoft YaHei !important;
                        
                    }
                        .signuptext{
                        font-size: 27px;
                        margin-left: 35%;
                        color: #fff;
                        }
                        .myeye{
                            margin-left: 86%!important;
                          font-size: 15px!important;
                          background-color: #000;
                          color:#fff;
                          padding: 5px;
                          border-radius: 5px;
                          /* margin-bottom: -29px; */
                          margin-top: -2px;
                          line-height: 1;
                          }
        .signin-wrap {
          /* width: auto; */
          border-radius: 50px 0px;
          /* overflow: hidden; */
          padding: 10px 20px;
          margin: 10;
          height: 100%;
          bottom: -20%;
          position: absolute;
          width: 100%;
      }

        i {
            font-size: 18px !important;
            color: #000;
            position: absolute !important;
            top: 12px !important;
            left: 10px !important;
            z-index: 99 !important;
        }
        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        
        /* Firefox */
        input[type=number] {
          -moz-appearance: textfield;
        }
        .navbar {
            max-width: 750px;
            position: fixed;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            height: .8rem;
            background: #337bd3 !important;
            width: 100%;
            left: 50%;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
            top: 0;
            z-index: 30;
        }

        .navbar span {
            text-align: center;
            font-size: .36rem;
            font-weight: 400;
            color: #ffffff !important;
        }

        input:focus {
            outline: none !important;
            border-color: #719ECE;
        }
        .btn-info.focus, .btn-info:focus{
            box-shadow: none !important;
        }
        .navbar {
            justify-content: flex-start;
        }

        .btn.register {
            background: #FFFFFF;
            color: #337bd3;
            border: 1px solid #0058AB;
            margin: 0 auto;
        }

        .item {
            width: 5.98rem;
            height: 0.88rem;
            background: #fff;
            border-radius: 0.14rem;
            margin: 0 auto;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            margin-bottom: 20px;
            position: relative;
            left: 0px;
            border:1px solid #aca0a0;
        }

        .logo {
            width: 250px;
            height: auto;
            margin: auto;
        }

        

        a,
        p {
            font-family: Microsoft YaHei !important;
        }

        .item:nth-child(1) input {
            padding-left: 17px;
        }

        .item:nth-child(2) input {
            padding-left: 17px;
        } 
        .item:nth-child(3) input {
            padding-left: 17px;
        } 
        .item:nth-child(4) input {
            padding-left: 17px;
        } 
        .item:nth-child(5) input {
            padding-left: 17px;
        }         `
                }}
            />
        </>
    )
}
